import * as Antd from "antd"
import * as React from "react"

import { ILoginFormFieldType } from "@components/dataEntry/LoginForm/LoginForm.tsx"

import loginFormService from "@services/loginFormService.ts"

export const useLoginForm = (
	form: Antd.FormInstance<ILoginFormFieldType>,
	initialValues: ILoginFormFieldType
) => {
	const values = Antd.Form.useWatch([], form)

	const [submittable, setSubmittable] = React.useState<boolean>(false)

	const onChange = React.useCallback(
		(
			name: keyof ILoginFormFieldType,
			value: ILoginFormFieldType[typeof name]
		) => loginFormService.setKey(name, value),
		[]
	)

	const clearForm = React.useCallback(() => {
		loginFormService.clearFormData()
	}, [])

	React.useEffect(() => {
		form.validateFields({ validateOnly: true })
			.then(() => setSubmittable(true))
			.catch(() => setSubmittable(false))
	}, [form, values])

	React.useEffect(() => {
		loginFormService.getFormData().then((formData) => {
			form.setFieldsValue({
				email: formData?.email || initialValues.email,
				password: formData?.password || initialValues.password,
			})
		})
	}, [initialValues])

	return { submittable, onChange, clearForm }
}
