import * as React from "react"
import styles from "./UserInfo.module.scss"

import DvUserAvatar from "@components/dataDisplay/DvUserAvatar/DvUserAvatar.tsx"
import useAuth from "@contexts/hooks/useAuth.tsx"

interface IProps {
	className?: string
}

const UserInfo: React.FC<IProps> = (props) => {
	const { loggedUser } = useAuth()

	return (
		<div className={`${styles.user_info} ${props.className || ""}`}>
			<DvUserAvatar className={`${styles.avatar} avatar`} size="large" />

			<div className={`${styles.data} data`}>
				{(loggedUser?.name || loggedUser?.lastName) && (
					<h1>
						{loggedUser?.name && loggedUser.name}{" "}
						{loggedUser?.lastName && loggedUser.lastName}
					</h1>
				)}

				{(loggedUser?.plan || loggedUser?.corporate) && (
					<div className={`${styles.membership} membership`}>
						<h3>{loggedUser?.plan && loggedUser.plan}</h3>
						<p>{loggedUser?.corporate && loggedUser.corporate}</p>
					</div>
				)}
			</div>
		</div>
	)
}

export default React.memo(UserInfo)
