import api from "@others/api"

import { IApiResponse } from "@interfaces/generic/IApiResponse"
import { Endpoints } from "@constants/Endpoints.tsx"
import { IPutUserResponseBodyApi } from "@interfaces/IPutUserResponseBodyApi.ts"
import { IPutUserResponseCli } from "@interfaces/IPutUserResponseCli.ts"
import { PutUserAdapter } from "@adapters/IPutUserAdapter.ts"
import { IPutUserResponseApi } from "@interfaces/IPutUserResponseApi.ts"
import { AppFunctions } from "@others/AppFunctions.ts"

const updateUserData = async (
	body: IPutUserResponseBodyApi
): Promise<IPutUserResponseCli> => {
	return api
		.put<IApiResponse<IPutUserResponseApi>>(Endpoints.user, body)
		.then(({ data }) => PutUserAdapter(data))
		.catch((error) => {
			throw new Error(AppFunctions.getErrorApiDetail(error))
		})
}

export default { updateUserData }
