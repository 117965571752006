import * as Antd from "antd"
import i18next from "i18next"

const requiredFieldRule = (message?: string): Antd.FormRule => ({
	required: true,
	message: message || i18next.t("this_field_is_required"),
})

const emailRule = (message?: string): Antd.FormRule => ({
	type: "email",
	message: message || i18next.t("this_email_is_not_valid"),
})

const phoneRule = (message?: string): Antd.FormRule => ({
	pattern: /^\+?[1-9]\d{1,14}$/, // E.164 format: +[country code][number]
	message: message || i18next.t("message_your_phone_is_not_valid"),
})

const checkedRule = (message?: string): Antd.FormRule => ({
	validator: (_, value) =>
		value
			? Promise.resolve()
			: Promise.reject(
					new Error(message || i18next.t("check_this_is_required"))
				),
})

export default { requiredFieldRule, emailRule, phoneRule, checkedRule }
