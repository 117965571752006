import * as Antd from "antd"
import styles from "./DvAntdFormItem.module.scss"

interface IProps<T> extends Antd.FormItemProps<T> {
	className?: string
}

const DvAntdFormItem = <T,>({ className, ...props }: IProps<T>) => {
	return (
		<Antd.Form.Item<T>
			className={`${styles.dv_antd_form_item} ${className || ""}`}
			wrapperCol={{ span: 24 }}
			validateTrigger={["onChange", "onBlur", "onSubmit"]}
			{...props}
		>
			{props.children}
		</Antd.Form.Item>
	)
}

export default DvAntdFormItem
