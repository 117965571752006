import * as React from "react"
import styles from "./DownloadApp.module.scss"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppMessage } from "@others/AppMessage.ts"

import DsLogoSDistrict from "@components/others/DsLogoSDistrict/DsLogoSDistrict.tsx"
import DsLogoBlancoElGlobo from "@components/others/DsLogoBlancoElGlobo/DsLogoBlancoElGlobo.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import Background from "@pages/UserProfile/DownloadApp/Background/Background.tsx"
import useAuthCtx from "@contexts/hooks/useAuth.tsx"
import bgImage from "@assets/jpg/ad-bg.jpg"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface IProps {
	className?: string
}

const DownloadApp: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { loggedUser } = useAuthCtx()
	const { isDesktop, isMobile } = useResponsiveToolCtx()

	const [isDownButtonDisabled, setDownloadButtonDisabled] =
		React.useState(false)

	const handleDownloadApp = () => {
		if (loggedUser?.hasAnApprovedMembership) {
			console.log("Download the app") // TODO: Implement download app
			return
		}

		if (loggedUser?.hasMembershipInProgress) {
			setDownloadButtonDisabled(true)

			AppMessage.info(t("message_you_have_a_membership_pending_approval"))
			return
		}

		if (
			!loggedUser?.hasMembershipInProgress &&
			!loggedUser?.hasAnApprovedMembership
		) {
			AppMessage.info(t("message_apply_a_membership_to_download_the_app"))
			navigate(AppRoutes.membershipPlans)
			return
		}
	}

	return (
		<div className={`${styles.download_app} ${props.className || ""}`}>
			<div
				className={styles.ad}
				style={
					isDesktop
						? {
								backgroundImage: `url(${bgImage})`,
								backgroundRepeat: "no-repeat",
								backgroundSize: "101%",
								backgroundPosition: "center",
							}
						: undefined
				}
			>
				{isMobile && <Background />}

				<main className={styles.main}>
					<header className={styles.header}>
						<div className={styles.logos}>
							<DsLogoSDistrict
								width={isDesktop ? 61 : 91}
								height={isDesktop ? 54 : 80}
							/>
							<DsLogoBlancoElGlobo
								type="vertical"
								width={isDesktop ? 48 : 72}
								height={isDesktop ? 54 : 80}
							/>
						</div>

						<div className={styles.headline}>
							<h1 className={styles.title}>Distrito El Globo</h1>

							<h3 className={styles.subtitle}>
								{t("at_your_fingertips")}
							</h3>
						</div>
					</header>

					<div className={styles.bottom}>
						<p className={styles.description}>
							{t("message_become_a_member")}
						</p>

						<footer className={styles.footer}>
							<DsButton
								className={styles.button}
								disabled={isDownButtonDisabled}
								onClick={handleDownloadApp}
							>
								{t("download_app")}
							</DsButton>
						</footer>
					</div>
				</main>
			</div>
		</div>
	)
}

export default React.memo(DownloadApp)
