import * as React from "react"
import styles from "./DvMessage_2.module.scss"

import { useTranslation } from "react-i18next"
import { AppExternalLinks } from "@constants/AppExternalLinks.ts"

interface IProps {
	className?: string
}

const DvMessage_2: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { membershipEmailLinkToSend } = AppExternalLinks()

	return (
		<p className={`${styles.dv_message_2} ${props.className || ""}`}>
			{t("message_membership_member_contact_us")}{" "}
			<span
				onClick={() => {
					window.open(membershipEmailLinkToSend, "_blank")
				}}
			>
				{t("support_team")}
			</span>
			.
		</p>
	)
}

export default React.memo(DvMessage_2)
