import React from "react"
import styles from "./AppLayout.module.scss"

import { Outlet } from "react-router-dom"

import DsNavbar from "@components/layout/DsNavbar/DsNavbar.tsx"

interface IProps {
	className?: string
}

const AppLayout: React.FC<IProps> = (props) => {
	return (
		<div className={`${styles.app_layout} ${props.className || ""}`}>
			<DsNavbar className={styles.nav_bar} />
			<Outlet />
		</div>
	)
}

export default React.memo(AppLayout)
