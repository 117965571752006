import dayjs from "dayjs"
import updateLocale from "dayjs/plugin/updateLocale"

import "dayjs/locale/es"
import "dayjs/locale/en"

dayjs.extend(updateLocale)

export const configureDayJs = (lang: string) => {
	dayjs.locale(lang)
}
