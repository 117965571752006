import i18n from "i18next"

import { AppFunctions } from "@others/AppFunctions.ts"
import { AppBusinessInfo } from "@constants/AppBusinessInfo.ts"

export const AppExternalLinks = () => {
	const englishHash = i18n.language === "en" ? "/en" : ""

	return {
		globo_home: `https://elglobodistrict.com${englishHash}/`,
		aboutUs: `https://elglobodistrict.com${englishHash}/#nosotros`,
		theProject: `https://elglobodistrict.com${englishHash}/#elproyecto`,
		workspaces: `https://elglobodistrict.com${englishHash}/#workspaces`,
		hotelBoutique: `https://elglobodistrict.com${englishHash}/#hotelboutique`,
		gastronomy: `https://elglobodistrict.com${englishHash}/#gastronomia`,
		art: `https://elglobodistrict.com${englishHash}/#arte`,
		invest: `https://elglobodistrict.com${englishHash}/invest/`,
		press: `https://elglobodistrict.com${englishHash}/#press`,
		impact: `https://elglobodistrict.com${englishHash}/impact/`,
		contact: `https://elglobodistrict.com${englishHash}/#contacto`,
		membership: `https://elglobodistrict.com${englishHash}/membership/`,
		instagram: "https://www.instagram.com/elglobo_district/",
		linkedin: "https://www.linkedin.com/company/elglobo-district/",

		emailLinkToSend: AppFunctions.openWebSendEmail(
			AppBusinessInfo.companyEmail,
			i18n.t("message_send_email_subject"),
			i18n.t("message_send_email_body")
		),
		infoEmailLinkToSend: AppFunctions.openWebSendEmail(
			AppBusinessInfo.infoEmail,
			i18n.t("message_send_info_email_subject"),
			i18n.t("message_send_info_email_body")
		),
		membershipEmailLinkToSend: AppFunctions.openWebSendEmail(
			AppBusinessInfo.membershipEmail,
			i18n.t("message_membership_email_subject"),
			i18n.t("message_membership_email_body")
		),
		whatsappLinkToSend: AppFunctions.openWebSendWhatsapp(
			AppBusinessInfo.companyPhone.countryCode,
			AppBusinessInfo.companyPhone.number,
			i18n.t("message_send_whatsapp")
		),
	}
}
