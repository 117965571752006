import * as React from "react"
import * as Antd from "antd"
import styles from "./DsButton.module.scss"

interface IProps {
	id?: string
	className?: string
	children?: React.ReactNode
	icon?: React.ReactNode
	type?: "primary" | "secondary" | "text"
	htmlType?: "button" | "submit" | "reset"
	onClick?: () => void
	loading?: boolean
	disabled?: boolean
	style?: React.CSSProperties
}

const DsButton: React.FC<IProps> = (props) => {
	const typeStyle = props.type ? styles[props.type] : styles.primary

	return (
		<Antd.Button
			id={props.id}
			className={`${styles.ds_button} ${props.className || ""} ${typeStyle}`}
			icon={props.icon}
			htmlType={props.htmlType}
			onClick={props.onClick}
			loading={props.loading}
			disabled={props.disabled}
			style={props.style}
		>
			{props.children}
		</Antd.Button>
	)
}

export default React.memo(DsButton)
