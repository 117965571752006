import * as React from "react"
import * as Antd from "antd"
import styles from "./LoginForm.module.scss"

import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppFunctions } from "@others/AppFunctions.ts"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"
import { AppMessage } from "@others/AppMessage.ts"
import { AppNotification } from "@others/AppNotification.ts"
import { useLoginForm } from "@hooks/useLoginForm.tsx"

import DvCheckbox from "@components/dataEntry/DvCheckbox/DvCheckbox.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import authService from "@services/authService.ts"
import useAuthCtx from "@contexts/hooks/useAuth.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"

export interface ILoginFormFieldType {
	email?: string
	password?: string
	remember?: boolean
}

interface IProps {
	className?: string
}

const LoginForm: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { setIsLogged, setLoggedUser } = useAuthCtx()

	const navigate = useNavigate()
	const location = useLocation()
	const lastUrlParam = AppFunctions.getUrlParam("lastUrl")

	const [form] = Antd.Form.useForm<ILoginFormFieldType>()
	const [loginLoading, setLoginLoading] = React.useState(false)

	const initialValues: ILoginFormFieldType = React.useMemo(
		() => ({
			remember: AppFunctions.isLoginRemembered() ?? true,
			email: location.state?.email,
		}),
		[location.state]
	)

	const { onChange, clearForm } = useLoginForm(form, initialValues)

	const onFinish = ({ email, password }: ILoginFormFieldType) => {
		if (!email || !password) return

		setLoginLoading(true)

		authService
			.login({
				email,
				password,
			})
			.then(({ user }) => {
				setIsLogged(true)
				setLoggedUser(user)
				AppMessage.success(t("login_successful"))

				if (!AppFunctions.isLoginRemembered()) clearForm()

				if (location.state?.from) {
					navigate(location.state.from, { replace: true })
					return
				} else if (lastUrlParam) {
					const lastUrl = new URL(lastUrlParam).pathname
					navigate(lastUrl, { replace: true })
					return
				} else if (
					location.state?.plan &&
					location.state?.paymentFrequency
				) {
					if (
						!user?.hasAnApprovedMembership &&
						!user?.hasMembershipInProgress
					) {
						navigate(AppRoutes.membershipStartApplying, {
							replace: true,
							state: {
								plan: location.state.plan,
								paymentFrequency:
									location.state.paymentFrequency,
							},
						})
					} else if (user?.hasAnApprovedMembership) {
						navigate(AppRoutes.userProfile, {
							replace: true,
							state: {
								hasAnApprovedMembership: true,
							},
						})
					} else if (user?.hasMembershipInProgress) {
						navigate(AppRoutes.userProfile, {
							replace: true,
							state: {
								hasMembershipInProgress: true,
							},
						})
					}
				} else {
					navigate(AppRoutes.userProfile, { replace: true })
				}
			})
			.catch(({ message }) => {
				AppNotification.error({
					message: message || t("something_went_wrong"),
				})
			})
			.finally(() => {
				setLoginLoading(false)
			})
	}

	React.useEffect(() => {
		if (
			AppFunctions.isAuthenticated() &&
			AppFunctions.isLoginRemembered()
		) {
			navigate(AppRoutes.userProfile, { replace: true })
		}
	}, [])

	return (
		<DvAntdForm<ILoginFormFieldType>
			form={form}
			className={`${styles.login_form} ${props.className || ""}`}
			initialValues={initialValues}
			onFinish={onFinish}
		>
			<div className={styles.top}>
				<header className={styles.header}>
					{t("insert_email_and_password")}
				</header>

				<main className={styles.body}>
					<DvAntdFormItem<ILoginFormFieldType>
						label={t("email")}
						name="email"
						rules={[
							{
								required: true,
								message: t("please_enter_your_email"),
							},
							{
								type: "email",
								message: t("your_email_is_not_valid"),
							},
						]}
					>
						<DvInput
							placeholder={t("insert_your_email")}
							onChange={(e) => onChange("email", e.target.value)}
						/>
					</DvAntdFormItem>

					<div className={styles.password_container}>
						<DvAntdFormItem<ILoginFormFieldType>
							label={t("password")}
							name="password"
							rules={[
								{
									required: true,
									message: t("please_enter_your_password"),
								},
							]}
						>
							<DvInput.Password
								placeholder={t("password")}
								onChange={(e) =>
									onChange("password", e.target.value)
								}
							/>
						</DvAntdFormItem>

						{/*<InputCaption*/}
						{/*	className={styles.input_caption}*/}
						{/*	text="Olvidé mi contraseña"*/}
						{/*/>*/}
					</div>

					<DvAntdFormItem<ILoginFormFieldType>
						className={styles.remember_me_item}
						name="remember"
						valuePropName="checked"
					>
						<DvCheckbox
							defaultChecked={AppFunctions.isLoginRemembered()}
							onChange={({ target }) => {
								localStorage.setItem(
									AppLocalStorageKeys.REMEMBER_ME,
									target.checked.toString()
								)
							}}
						>
							{t("remember_me")}
						</DvCheckbox>
					</DvAntdFormItem>
				</main>
			</div>

			<div className={styles.footer}>
				<DsButton
					className={styles.login_button}
					htmlType="submit"
					loading={loginLoading}
				>
					{t("start_session")}
				</DsButton>

				<div className={styles.footer_text}>
					<label className={styles.label}>
						{t("dont_have_an_account_yet")}
					</label>
					<span
						className={styles.sign_up_link}
						onClick={() =>
							navigate(AppRoutes.validateAccount, {
								replace: true,
							})
						}
					>
						{t("create_account")}
					</span>
				</div>
			</div>
		</DvAntdForm>
	)
}

export default React.memo(LoginForm)
