export interface IJobRole {
	value: string
	label: string
}

export const ListJobRolesEs: IJobRole[] = [
	{ value: "accessory_designer", label: "Diseñador de accesorios" },
	{ value: "accountant", label: "Contador" },
	{ value: "account_coordinator", label: "Coordinador de cuentas" },
	{ value: "account_executive", label: "Ejecutivo de cuentas" },
	{ value: "account_manager", label: "Gerente de cuentas" },
	{ value: "actor_actress", label: "Actor / Actriz" },
	{ value: "advertising_designer", label: "Diseñador publicitario" },
	{ value: "advisor", label: "Asesor" },
	{ value: "aeronautical_engineer", label: "Ingeniero aeronáutico" },
	{ value: "agent", label: "Agente" },
	{ value: "analyst", label: "Analista" },
	{ value: "anchorman", label: "Presentador de noticias" },
	{ value: "animator", label: "Animador" },
	{ value: "anthropologist", label: "Antropólogo" },
	{ value: "architect", label: "Arquitecto" },
	{ value: "art_administrator", label: "Administrador de arte" },
	{ value: "artisan", label: "Artesano" },
	{ value: "artist", label: "Artista" },
	{ value: "artist_manager", label: "Representante de artistas" },
	{ value: "artistic_director", label: "Director artístico" },
	{
		value: "assistant_stage_manager",
		label: "Asistente de dirección escénica",
	},
	{ value: "associate", label: "Asociado" },
	{ value: "astronaut", label: "Astronauta" },
	{ value: "astronomer", label: "Astrónomo" },
	{ value: "audio_engineer", label: "Ingeniero de audio" },
	{ value: "author", label: "Autor" },
	{ value: "backup_dancer", label: "Bailarín de respaldo" },
	{ value: "ballet_dancer", label: "Bailarín de ballet" },
	{ value: "ballet_historian", label: "Historiador de ballet" },
	{ value: "ballet_master", label: "Maestro de ballet" },
	{ value: "banker", label: "Banquero" },
	{ value: "barrister", label: "Abogado" },
	{ value: "bartender", label: "Bartender" },
	{ value: "beauty_therapist", label: "Terapeuta de belleza" },
	{ value: "benshi", label: "Benshi (narrador japonés)" },
	{ value: "blogger", label: "Bloguero" },
	{ value: "board_member", label: "Miembro de la junta" },
	{ value: "botanist", label: "Botánico" },
	{ value: "brand_ambassador", label: "Embajador de marca" },
	{ value: "business_analyst", label: "Analista de negocios" },
	{ value: "buyer", label: "Comprador" },
	{ value: "crm_executive", label: "Ejecutivo de CRM" },
	{ value: "campaign_manager", label: "Director de campañas" },
	{ value: "carpenter", label: "Carpintero" },
	{ value: "cartoonist", label: "Caricaturista" },
	{ value: "casting_director", label: "Director de casting" },
	{ value: "ceramics_artist", label: "Artista de cerámica" },
	{ value: "chairman", label: "Presidente" },
	{ value: "chef", label: "Chef" },
	{ value: "chemical_engineer", label: "Ingeniero químico" },
	{ value: "chief_analytics_officer", label: "Director de análisis" },
	{ value: "chief_brand_officer", label: "Director de marca" },
	{ value: "chief_business_officer", label: "Director de negocios" },
	{ value: "chief_commercial_officer", label: "Director comercial" },
	{
		value: "chief_communications_officer",
		label: "Director de comunicaciones",
	},
	{ value: "chief_content_officer", label: "Director de contenido" },
	{ value: "chief_creative_officer", label: "Director creativo" },
	{ value: "chief_customer_officer", label: "Director de clientes" },
	{ value: "chief_data_officer", label: "Director de datos" },
	{ value: "chief_design_officer", label: "Director de diseño" },
	{ value: "chief_digital_officer", label: "Director digital" },
	{ value: "chief_diversity_officer", label: "Director de diversidad" },
	{ value: "chief_events_officer", label: "Director de eventos" },
	{ value: "chief_executive_officer", label: "Director ejecutivo" },
	{ value: "chief_experience_officer", label: "Director de experiencia" },
	{ value: "chief_financial_officer", label: "Director financiero" },
	{ value: "chief_information_officer", label: "Director de información" },
	{ value: "chief_innovation_officer", label: "Director de innovación" },
	{ value: "chief_legal_officer", label: "Director legal" },
	{ value: "chief_marketing_officer", label: "Director de marketing" },
	{ value: "chief_operating_officer", label: "Director de operaciones" },
	{ value: "chief_people_officer", label: "Director de personal" },
	{ value: "chief_product_officer", label: "Director de producto" },
	{ value: "chief_research_officer", label: "Director de investigación" },
	{ value: "chief_revenue_officer", label: "Director de ingresos" },
	{ value: "chief_risk_officer", label: "Director de riesgos" },
	{ value: "chief_strategy_officer", label: "Director de estrategia" },
	{
		value: "chief_sustainability_officer",
		label: "Director de sostenibilidad",
	},
	{ value: "chief_technology_officer", label: "Director de tecnología" },
	{ value: "choreographer", label: "Coreógrafo" },
	{ value: "cinematographer", label: "Cinematógrafo" },
	{ value: "cleric", label: "Clérigo" },
	{ value: "colourist", label: "Colorista" },
	{ value: "comedian", label: "Comediante" },
	{ value: "commissioning_editor", label: "Editor de comisiones" },
	{
		value: "communication_coordinator",
		label: "Coordinador de comunicaciones",
	},
	{ value: "communication_director", label: "Director de comunicaciones" },
	{ value: "communication_manager", label: "Gerente de comunicaciones" },
	{ value: "community_manager", label: "Gerente de comunidad" },
	{ value: "company_manager", label: "Gerente de empresa" },
	{ value: "composer", label: "Compositor" },
	{ value: "computer_analyst", label: "Analista de computadoras" },
	{ value: "computer_scientist", label: "Científico informático" },
	{ value: "concept_artist", label: "Artista conceptual" },
	{ value: "consultant", label: "Consultor" },
	{ value: "content_editor", label: "Editor de contenido" },
	{ value: "content_creator", label: "Creador de contenido" },
	{ value: "copy_editor", label: "Editor de textos" },
	{ value: "copywriter", label: "Redactor" },
	{ value: "cosmetic_surgeon", label: "Cirujano estético" },
	{ value: "costume_designer", label: "Diseñador de vestuario" },
	{ value: "costume_director", label: "Director de vestuario" },
	{ value: "creative_consultant", label: "Consultor creativo" },
	{ value: "creative_director", label: "Director creativo" },
	{ value: "curator", label: "Curador" },
	{ value: "dj", label: "DJ" },
	{ value: "dance_critic", label: "Crítico de danza" },
	{ value: "dance_scholar", label: "Académico de danza" },
	{ value: "dancer", label: "Bailarín" },
	{ value: "data_analyst", label: "Analista de datos" },
	{ value: "data_designer", label: "Diseñador de datos" },
	{ value: "data_scientist", label: "Científico de datos" },
	{
		value: "demographic_marketer",
		label: "Especialista en marketing demográfico",
	},
	{ value: "design_director", label: "Director de diseño" },
	{ value: "design_strategist", label: "Estratega de diseño" },
	{ value: "designer", label: "Diseñador" },
	{
		value: "digital_marketing_manager",
		label: "Gerente de marketing digital",
	},
	{ value: "diplomat", label: "Diplomático" },
	{ value: "director", label: "Director" },
	{ value: "director_of_development", label: "Director de desarrollo" },
	{ value: "director_of_photography", label: "Director de fotografía" },
	{ value: "director_of_production", label: "Director de producción" },
	{
		value: "director_of_public_relations",
		label: "Director de relaciones públicas",
	},
	{
		value: "director_of_special_events",
		label: "Director de eventos especiales",
	},
	{ value: "doctor", label: "Médico" },
	{ value: "dresser", label: "Vestuarista" },
	{ value: "economist", label: "Economista" },
	{ value: "editor", label: "Editor" },
	{ value: "editor_in_chief", label: "Editor en jefe" },
	{ value: "electrical_engineer", label: "Ingeniero eléctrico" },
	{ value: "electrician", label: "Electricista" },
	{ value: "engineer", label: "Ingeniero" },
	{ value: "estate_agent", label: "Agente inmobiliario" },
	{ value: "event_planner", label: "Planificador de eventos" },
	{ value: "events_manager", label: "Gerente de eventos" },
	{ value: "executive_assistant", label: "Asistente ejecutivo" },
	{
		value: "executive_creative_director",
		label: "Director creativo ejecutivo",
	},
	{ value: "executive_director", label: "Director ejecutivo" },
	{ value: "executive_producer", label: "Productor ejecutivo" },
	{ value: "farmer", label: "Granjero" },
	{ value: "fashion_designer", label: "Diseñador de moda" },
	{ value: "film_director", label: "Director de cine" },
	{ value: "film_editor", label: "Editor de cine" },
	{ value: "film_producer", label: "Productor de cine" },
	{ value: "filmmaker", label: "Cineasta" },
	{
		value: "financial_planner_analyst",
		label: "Planificador / Analista financiero",
	},
	{ value: "fine_artist", label: "Artista plástico" },
	{ value: "flight_crew", label: "Tripulación de vuelo" },
	{ value: "floral_designer", label: "Diseñador floral" },
	{ value: "florist", label: "Florista" },
	{ value: "football_coach", label: "Entrenador de fútbol" },
	{ value: "footballer", label: "Futbolista" },
	{ value: "founder", label: "Fundador" },
	{ value: "freelancer", label: "Freelancer" },
	{ value: "front_of_house_manager", label: "Gerente de recepción" },
	{ value: "gardener", label: "Jardinero" },
	{ value: "general_manager", label: "Gerente general" },
	{ value: "geographer", label: "Geógrafo" },
	{ value: "graphic_designer", label: "Diseñador gráfico" },
	{ value: "grips", label: "Técnico de agarre" },
	{ value: "hairstylist", label: "Estilista" },
	{ value: "hardware_engineer", label: "Ingeniero de hardware" },
	{ value: "head_chef", label: "Chef principal" },
	{ value: "head_of_crm", label: "Director de CRM" },
	{ value: "head_of_production", label: "Jefe de producción" },
	{ value: "historian", label: "Historiador" },
	{ value: "horse_trainer", label: "Entrenador de caballos" },
	{ value: "house_manager", label: "Gerente de teatro" },
	{ value: "housewife", label: "Ama de casa" },
	{ value: "hr_advisor", label: "Asesor de RRHH" },
	{ value: "illusionist", label: "Ilusionista" },
	{ value: "illustrator", label: "Ilustrador" },
	{ value: "impressionist", label: "Impresionista" },
	{ value: "influencer", label: "Influencer" },
	{ value: "interior_designer", label: "Diseñador de interiores" },
	{ value: "inventor", label: "Inventor" },
	{ value: "jewellery_designer", label: "Diseñador de joyas" },
	{ value: "jockey", label: "Jinete" },
	{ value: "journalist", label: "Periodista" },
	{ value: "landowner", label: "Propietario de tierras" },
	{ value: "landscape_artist", label: "Artista paisajista" },
	{ value: "lawyer", label: "Abogado" },
	{ value: "lecturer", label: "Profesor" },
	{ value: "life_coach", label: "Entrenador de vida" },
	{ value: "lighting_designer", label: "Diseñador de iluminación" },
	{ value: "lighting_technician", label: "Técnico de iluminación" },
	{ value: "literary_editor", label: "Editor literario" },
	{ value: "literary_manager", label: "Gerente literario" },
	{ value: "logistics_manager", label: "Gerente de logística" },
	{ value: "lyricist", label: "Letrista" },
	{ value: "magician", label: "Mago" },
	{ value: "make_up_artist", label: "Maquillador" },
	{ value: "marketeer", label: "Especialista en marketing" },
	{ value: "marketing_director", label: "Director de marketing" },
	{ value: "master_of_ceremonies", label: "Maestro de ceremonias" },
	{ value: "mathematician", label: "Matemático" },
	{ value: "mechanical_engineer", label: "Ingeniero mecánico" },
	{ value: "media_designer", label: "Diseñador de medios" },
	{ value: "membership_manager", label: "Gerente de membresía" },
	{ value: "merchandiser", label: "Encargado de mercancías" },
	{ value: "military_officer", label: "Oficial militar" },
	{ value: "model", label: "Modelo" },
	{ value: "music_director", label: "Director musical" },
	{ value: "musician", label: "Músico" },
	{ value: "news_presenter", label: "Presentador de noticias" },
	{ value: "newscaster", label: "Reportero" },
	{ value: "novelist", label: "Novelista" },
	{ value: "nurse", label: "Enfermero" },
	{ value: "nutritionist", label: "Nutricionista" },
	{ value: "operations_director", label: "Director de operaciones" },
	{ value: "operations_manager", label: "Gerente de operaciones" },
	{ value: "optician", label: "Óptico" },
	{ value: "partner", label: "Socio" },
	{ value: "partnerships_manager", label: "Gerente de asociaciones" },
	{ value: "party_planner", label: "Planificador de fiestas" },
	{ value: "pattern_maker", label: "Patronista" },
	{ value: "penciller", label: "Dibujante" },
	{ value: "philanthropist", label: "Filántropo" },
	{ value: "photographer", label: "Fotógrafo" },
	{ value: "photojournalist", label: "Fotoperiodista" },
	{ value: "pilot", label: "Piloto" },
	{ value: "playbill_writer", label: "Escritor de carteles de teatro" },
	{ value: "playwright", label: "Dramaturgo" },
	{ value: "poet", label: "Poeta" },
	{
		value: "police_security_officer",
		label: "Oficial de policía / seguridad",
	},
	{ value: "principal_dancer", label: "Bailarín principal" },
	{ value: "producer", label: "Productor" },
	{ value: "product_designer", label: "Diseñador de productos" },
	{ value: "product_manager", label: "Gerente de producto" },
	{ value: "production_assistant", label: "Asistente de producción" },
	{ value: "production_designer", label: "Diseñador de producción" },
	{ value: "production_manager", label: "Gerente de producción" },
	{ value: "professional_sportsperson", label: "Deportista profesional" },
	{ value: "programmer", label: "Programador" },
	{ value: "project_manager", label: "Gerente de proyectos" },
	{ value: "psychiatrist", label: "Psiquiatra" },
	{ value: "psychic", label: "Psíquico" },
	{ value: "psychologist", label: "Psicólogo" },
	{ value: "psychotherapist", label: "Psicoterapeuta" },
	{ value: "publicist", label: "Publicista" },
	{ value: "radio_producer", label: "Productor de radio" },
	{ value: "receptionist", label: "Recepcionista" },
	{ value: "record_producer", label: "Productor discográfico" },
	{ value: "reporter", label: "Reportero" },
	{ value: "researcher", label: "Investigador" },
	{ value: "retired", label: "Jubilado" },
	{
		value: "sales_and_marketing_coordinator",
		label: "Coordinador de ventas y marketing",
	},
	{
		value: "sales_and_marketing_manager",
		label: "Gerente de ventas y marketing",
	},
	{ value: "scenic_artist", label: "Artista escénico" },
	{ value: "scenic_designer", label: "Diseñador escénico" },
	{ value: "scenographer", label: "Escenógrafo" },
	{ value: "scientist", label: "Científico" },
	{ value: "screenwriter", label: "Guionista" },
	{ value: "scribe", label: "Escriba" },
	{ value: "script_coordinator", label: "Coordinador de guiones" },
	{ value: "sculptor", label: "Escultor" },
	{ value: "senior_designer", label: "Diseñador senior" },
	{ value: "set_decorator", label: "Decorador de sets" },
	{ value: "set_designer", label: "Diseñador de sets" },
	{ value: "set_dresser", label: "Vestuarista" },
	{ value: "showjumper", label: "Jinete de saltos" },
	{ value: "showrunner", label: "Productor ejecutivo de serie" },
	{ value: "singer", label: "Cantante" },
	{ value: "sociologist", label: "Sociólogo" },
	{ value: "software_analyst", label: "Analista de software" },
	{ value: "software_design", label: "Diseñador de software" },
	{ value: "software_engineer", label: "Ingeniero de software" },
	{
		value: "software_quality_analyst",
		label: "Analista de calidad de software",
	},
	{ value: "solicitor", label: "Abogado" },
	{ value: "soloist", label: "Solista" },
	{ value: "solution_architect", label: "Arquitecto de soluciones" },
	{ value: "songwriter", label: "Compositor" },
	{ value: "sound_designer", label: "Diseñador de sonido" },
	{ value: "speechwriter", label: "Escritor de discursos" },
	{ value: "spotlight_operator", label: "Operador de luces" },
	{ value: "student", label: "Estudiante" },
	{ value: "stunt_coordinator", label: "Coordinador de acrobacias" },
	{ value: "stunt_performer", label: "Acróbata" },
	{ value: "stylist", label: "Estilista" },
	{ value: "surveyor", label: "Topógrafo" },
	{ value: "systems_analyst", label: "Analista de sistemas" },
	{ value: "tattoo_artist", label: "Tatuador" },
	{ value: "teacher", label: "Profesor" },
	{ value: "technical_director", label: "Director técnico" },
	{ value: "technical_writer", label: "Redactor técnico" },
	{ value: "technician", label: "Técnico" },
	{ value: "test_engineer", label: "Ingeniero de pruebas" },
	{ value: "theatre_manager", label: "Gerente de teatro" },
	{ value: "ticketing_agent", label: "Agente de boletos" },
	{ value: "urban_planner", label: "Urbanista" },
	{
		value: "user_experience_designer",
		label: "Diseñador de experiencia de usuario",
	},
	{
		value: "user_interaction_designer",
		label: "Diseñador de interacción de usuario",
	},
	{ value: "user_researcher", label: "Investigador de usuarios" },
	{ value: "veterinarian", label: "Veterinario" },
	{ value: "video_editor", label: "Editor de video" },
	{ value: "videographer", label: "Videógrafo" },
	{ value: "visual_designer", label: "Diseñador visual" },
	{ value: "vocalist", label: "Vocalista" },
	{ value: "voice_actor", label: "Actor de doblaje" },
	{ value: "volunteer", label: "Voluntario" },
	{ value: "waiter", label: "Camarero" },
	{ value: "wardrobe_supervisor", label: "Supervisor de vestuario" },
	{ value: "weatherperson", label: "Meteorólogo" },
	{ value: "web_designer", label: "Diseñador web" },
	{ value: "web_developer", label: "Desarrollador web" },
	{ value: "website_content_writer", label: "Redactor de contenido web" },
	{ value: "wedding_planner", label: "Organizador de bodas" },
	{ value: "wellness_coordinator", label: "Coordinador de bienestar" },
	{ value: "writer", label: "Escritor" },
	{ value: "yoga_instructor", label: "Instructor de yoga" },
	{ value: "zoologist", label: "Zoólogo" },
]

export const ListJobRolesEn: IJobRole[] = [
	{ value: "accessory_designer", label: "Accessory designer" },
	{ value: "accountant", label: "Accountant" },
	{ value: "account_coordinator", label: "Account coordinator" },
	{ value: "account_executive", label: "Account executive" },
	{ value: "account_manager", label: "Account manager" },
	{ value: "actor_actress", label: "Actor / Actress" },
	{ value: "advertising_designer", label: "Advertising designer" },
	{ value: "advisor", label: "Advisor" },
	{ value: "aeronautical_engineer", label: "Aeronautical engineer" },
	{ value: "agent", label: "Agent" },
	{ value: "analyst", label: "Analyst" },
	{ value: "anchorman", label: "Anchorman" },
	{ value: "animator", label: "Animator" },
	{ value: "anthropologist", label: "Anthropologist" },
	{ value: "architect", label: "Architect" },
	{ value: "art_administrator", label: "Art administrator" },
	{ value: "artisan", label: "Artisan" },
	{ value: "artist", label: "Artist" },
	{ value: "artist_manager", label: "Artist manager" },
	{ value: "artistic_director", label: "Artistic director" },
	{ value: "assistant_stage_manager", label: "Assistant stage manager" },
	{ value: "associate", label: "Associate" },
	{ value: "astronaut", label: "Astronaut" },
	{ value: "astronomer", label: "Astronomer" },
	{ value: "audio_engineer", label: "Audio engineer" },
	{ value: "author", label: "Author" },
	{ value: "backup_dancer", label: "Backup dancer" },
	{ value: "ballet_dancer", label: "Ballet dancer" },
	{ value: "ballet_historian", label: "Ballet historian" },
	{ value: "ballet_master", label: "Ballet master" },
	{ value: "banker", label: "Banker" },
	{ value: "barrister", label: "Barrister" },
	{ value: "bartender", label: "Bartender" },
	{ value: "beauty_therapist", label: "Beauty therapist" },
	{ value: "benshi", label: "Benshi" },
	{ value: "blogger", label: "Blogger" },
	{ value: "board_member", label: "Board member" },
	{ value: "botanist", label: "Botanist" },
	{ value: "brand_ambassador", label: "Brand ambassador" },
	{ value: "business_analyst", label: "Business analyst" },
	{ value: "buyer", label: "Buyer" },
	{ value: "crm_executive", label: "CRM executive" },
	{ value: "campaign_manager", label: "Campaign manager" },
	{ value: "carpenter", label: "Carpenter" },
	{ value: "cartoonist", label: "Cartoonist" },
	{ value: "casting_director", label: "Casting director" },
	{ value: "ceramics_artist", label: "Ceramics artist" },
	{ value: "chairman", label: "Chairman" },
	{ value: "chef", label: "Chef" },
	{ value: "chemical_engineer", label: "Chemical engineer" },
	{ value: "chief_analytics_officer", label: "Chief analytics officer" },
	{ value: "chief_brand_officer", label: "Chief brand officer" },
	{ value: "chief_business_officer", label: "Chief business officer" },
	{ value: "chief_commercial_officer", label: "Chief commercial officer" },
	{
		value: "chief_communications_officer",
		label: "Chief communications officer",
	},
	{ value: "chief_content_officer", label: "Chief content officer" },
	{ value: "chief_creative_officer", label: "Chief creative officer" },
	{ value: "chief_customer_officer", label: "Chief customer officer" },
	{ value: "chief_data_officer", label: "Chief data officer" },
	{ value: "chief_design_officer", label: "Chief design officer" },
	{ value: "chief_digital_officer", label: "Chief digital officer" },
	{ value: "chief_diversity_officer", label: "Chief diversity officer" },
	{ value: "chief_events_officer", label: "Chief events officer" },
	{ value: "chief_executive_officer", label: "Chief executive officer" },
	{ value: "chief_experience_officer", label: "Chief experience officer" },
	{ value: "chief_financial_officer", label: "Chief financial officer" },
	{ value: "chief_information_officer", label: "Chief information officer" },
	{ value: "chief_innovation_officer", label: "Chief innovation officer" },
	{ value: "chief_legal_officer", label: "Chief legal officer" },
	{ value: "chief_marketing_officer", label: "Chief marketing officer" },
	{ value: "chief_operating_officer", label: "Chief operating officer" },
	{ value: "chief_people_officer", label: "Chief people officer" },
	{ value: "chief_product_officer", label: "Chief product officer" },
	{ value: "chief_research_officer", label: "Chief research officer" },
	{ value: "chief_revenue_officer", label: "Chief revenue officer" },
	{ value: "chief_risk_officer", label: "Chief risk officer" },
	{ value: "chief_strategy_officer", label: "Chief strategy officer" },
	{
		value: "chief_sustainability_officer",
		label: "Chief sustainability officer",
	},
	{ value: "chief_technology_officer", label: "Chief technology officer" },
	{ value: "choreographer", label: "Choreographer" },
	{ value: "cinematographer", label: "Cinematographer" },
	{ value: "cleric", label: "Cleric" },
	{ value: "colourist", label: "Colourist" },
	{ value: "comedian", label: "Comedian" },
	{ value: "commissioning_editor", label: "Commissioning editor" },
	{ value: "communication_coordinator", label: "Communication coordinator" },
	{ value: "communication_director", label: "Communication director" },
	{ value: "communication_manager", label: "Communication manager" },
	{ value: "community_manager", label: "Community manager" },
	{ value: "company_manager", label: "Company manager" },
	{ value: "composer", label: "Composer" },
	{ value: "computer_analyst", label: "Computer analyst" },
	{ value: "computer_scientist", label: "Computer scientist" },
	{ value: "concept_artist", label: "Concept artist" },
	{ value: "consultant", label: "Consultant" },
	{ value: "content_editor", label: "Content editor" },
	{ value: "content_creator", label: "Content creator" },
	{ value: "copy_editor", label: "Copy editor" },
	{ value: "copywriter", label: "Copywriter" },
	{ value: "cosmetic_surgeon", label: "Cosmetic surgeon" },
	{ value: "costume_designer", label: "Costume designer" },
	{ value: "costume_director", label: "Costume director" },
	{ value: "creative_consultant", label: "Creative consultant" },
	{ value: "creative_director", label: "Creative director" },
	{ value: "curator", label: "Curator" },
	{ value: "dj", label: "DJ" },
	{ value: "dance_critic", label: "Dance critic" },
	{ value: "dance_scholar", label: "Dance scholar" },
	{ value: "dancer", label: "Dancer" },
	{ value: "data_analyst", label: "Data analyst" },
	{ value: "data_designer", label: "Data designer" },
	{ value: "data_scientist", label: "Data scientist" },
	{ value: "demographic_marketer", label: "Demographic marketer" },
	{ value: "design_director", label: "Design director" },
	{ value: "design_strategist", label: "Design strategist" },
	{ value: "designer", label: "Designer" },
	{ value: "digital_marketing_manager", label: "Digital marketing manager" },
	{ value: "diplomat", label: "Diplomat" },
	{ value: "director", label: "Director" },
	{ value: "director_of_development", label: "Director of development" },
	{ value: "director_of_photography", label: "Director of photography" },
	{ value: "director_of_production", label: "Director of production" },
	{
		value: "director_of_public_relations",
		label: "Director of public relations",
	},
	{
		value: "director_of_special_events",
		label: "Director of special events",
	},
	{ value: "doctor", label: "Doctor" },
	{ value: "dresser", label: "Dresser" },
	{ value: "economist", label: "Economist" },
	{ value: "editor", label: "Editor" },
	{ value: "editor_in_chief", label: "Editor in chief" },
	{ value: "electrical_engineer", label: "Electrical engineer" },
	{ value: "electrician", label: "Electrician" },
	{ value: "engineer", label: "Engineer" },
	{ value: "estate_agent", label: "Estate agent" },
	{ value: "event_planner", label: "Event planner" },
	{ value: "events_manager", label: "Events manager" },
	{ value: "executive_assistant", label: "Executive assistant" },
	{
		value: "executive_creative_director",
		label: "Executive creative director",
	},
	{ value: "executive_director", label: "Executive director" },
	{ value: "executive_producer", label: "Executive producer" },
	{ value: "farmer", label: "Farmer" },
	{ value: "fashion_designer", label: "Fashion designer" },
	{ value: "film_director", label: "Film director" },
	{ value: "film_editor", label: "Film editor" },
	{ value: "film_producer", label: "Film producer" },
	{ value: "filmmaker", label: "Filmmaker" },
	{
		value: "financial_planner_analyst",
		label: "Financial planner / analyst",
	},
	{ value: "fine_artist", label: "Fine artist" },
	{ value: "flight_crew", label: "Flight crew" },
	{ value: "floral_designer", label: "Floral designer" },
	{ value: "florist", label: "Florist" },
	{ value: "football_coach", label: "Football coach" },
	{ value: "footballer", label: "Footballer" },
	{ value: "founder", label: "Founder" },
	{ value: "freelancer", label: "Freelancer" },
	{ value: "front_of_house_manager", label: "Front of house manager" },
	{ value: "gardener", label: "Gardener" },
	{ value: "general_manager", label: "General manager" },
	{ value: "geographer", label: "Geographer" },
	{ value: "graphic_designer", label: "Graphic designer" },
	{ value: "grips", label: "Grips" },
	{ value: "hairstylist", label: "Hairstylist" },
	{ value: "hardware_engineer", label: "Hardware engineer" },
	{ value: "head_chef", label: "Head chef" },
	{ value: "head_of_crm", label: "Head of CRM" },
	{ value: "head_of_production", label: "Head of production" },
	{ value: "historian", label: "Historian" },
	{ value: "horse_trainer", label: "Horse trainer" },
	{ value: "house_manager", label: "House manager" },
	{ value: "housewife", label: "Housewife" },
	{ value: "hr_advisor", label: "HR Advisor" },
	{ value: "illusionist", label: "Illusionist" },
	{ value: "illustrator", label: "Illustrator" },
	{ value: "impressionist", label: "Impressionist" },
	{ value: "influencer", label: "Influencer" },
	{ value: "interior_designer", label: "Interior designer" },
	{ value: "inventor", label: "Inventor" },
	{ value: "jewellery_designer", label: "Jewellery designer" },
	{ value: "jockey", label: "Jockey" },
	{ value: "journalist", label: "Journalist" },
	{ value: "landowner", label: "Landowner" },
	{ value: "landscape_artist", label: "Landscape artist" },
	{ value: "lawyer", label: "Lawyer" },
	{ value: "lecturer", label: "Lecturer" },
	{ value: "life_coach", label: "Life coach" },
	{ value: "lighting_designer", label: "Lighting designer" },
	{ value: "lighting_technician", label: "Lighting technician" },
	{ value: "literary_editor", label: "Literary editor" },
	{ value: "literary_manager", label: "Literary manager" },
	{ value: "logistics_manager", label: "Logistics manager" },
	{ value: "lyricist", label: "Lyricist" },
	{ value: "magician", label: "Magician" },
	{ value: "make_up_artist", label: "Make-up artist" },
	{ value: "marketeer", label: "Marketeer" },
	{ value: "marketing_director", label: "Marketing director" },
	{ value: "master_of_ceremonies", label: "Master of ceremonies" },
	{ value: "mathematician", label: "Mathematician" },
	{ value: "mechanical_engineer", label: "Mechanical engineer" },
	{ value: "media_designer", label: "Media designer" },
	{ value: "membership_manager", label: "Membership manager" },
	{ value: "merchandiser", label: "Merchandiser" },
	{ value: "military_officer", label: "Military officer" },
	{ value: "model", label: "Model" },
	{ value: "music_director", label: "Music director" },
	{ value: "musician", label: "Musician" },
	{ value: "news_presenter", label: "News presenter" },
	{ value: "newscaster", label: "Newscaster" },
	{ value: "novelist", label: "Novelist" },
	{ value: "nurse", label: "Nurse" },
	{ value: "nutritionist", label: "Nutritionist" },
	{ value: "operations_director", label: "Operations director" },
	{ value: "operations_manager", label: "Operations manager" },
	{ value: "optician", label: "Optician" },
	{ value: "partner", label: "Partner" },
	{ value: "partnerships_manager", label: "Partnerships manager" },
	{ value: "party_planner", label: "Party planner" },
	{ value: "pattern_maker", label: "Pattern maker" },
	{ value: "penciller", label: "Penciller" },
	{ value: "philanthropist", label: "Philanthropist" },
	{ value: "photographer", label: "Photographer" },
	{ value: "photojournalist", label: "Photojournalist" },
	{ value: "pilot", label: "Pilot" },
	{ value: "playbill_writer", label: "Playbill writer" },
	{ value: "playwright", label: "Playwright" },
	{ value: "poet", label: "Poet" },
	{ value: "police_security_officer", label: "Police / Security officer" },
	{ value: "principal_dancer", label: "Principal dancer" },
	{ value: "producer", label: "Producer" },
	{ value: "product_designer", label: "Product designer" },
	{ value: "product_manager", label: "Product manager" },
	{ value: "production_assistant", label: "Production assistant" },
	{ value: "production_designer", label: "Production designer" },
	{ value: "production_manager", label: "Production manager" },
	{ value: "professional_sportsperson", label: "Professional sportsperson" },
	{ value: "programmer", label: "Programmer" },
	{ value: "project_manager", label: "Project manager" },
	{ value: "psychiatrist", label: "Psychiatrist" },
	{ value: "psychic", label: "Psychic" },
	{ value: "psychologist", label: "Psychologist" },
	{ value: "psychotherapist", label: "Psychotherapist" },
	{ value: "publicist", label: "Publicist" },
	{ value: "radio_producer", label: "Radio producer" },
	{ value: "receptionist", label: "Receptionist" },
	{ value: "record_producer", label: "Record producer" },
	{ value: "reporter", label: "Reporter" },
	{ value: "researcher", label: "Researcher" },
	{ value: "retired", label: "Retired" },
	{
		value: "sales_and_marketing_coordinator",
		label: "Sales and marketing coordinator",
	},
	{
		value: "sales_and_marketing_manager",
		label: "Sales and marketing manager",
	},
	{ value: "scenic_artist", label: "Scenic artist" },
	{ value: "scenic_designer", label: "Scenic designer" },
	{ value: "scenographer", label: "Scenographer" },
	{ value: "scientist", label: "Scientist" },
	{ value: "screenwriter", label: "Screenwriter" },
	{ value: "scribe", label: "Scribe" },
	{ value: "script_coordinator", label: "Script coordinator" },
	{ value: "sculptor", label: "Sculptor" },
	{ value: "senior_designer", label: "Senior designer" },
	{ value: "set_decorator", label: "Set decorator" },
	{ value: "set_designer", label: "Set designer" },
	{ value: "set_dresser", label: "Set dresser" },
	{ value: "showjumper", label: "Showjumper" },
	{ value: "showrunner", label: "Showrunner" },
	{ value: "singer", label: "Singer" },
	{ value: "sociologist", label: "Sociologist" },
	{ value: "software_analyst", label: "Software analyst" },
	{ value: "software_designer", label: "Software designer" },
	{ value: "software_engineer", label: "Software engineer" },
	{ value: "software_quality_analyst", label: "Software quality analyst" },
	{ value: "solicitor", label: "Solicitor" },
	{ value: "soloist", label: "Soloist" },
	{ value: "solution_architect", label: "Solution architect" },
	{ value: "songwriter", label: "Songwriter" },
	{ value: "sound_designer", label: "Sound designer" },
	{ value: "speechwriter", label: "Speechwriter" },
	{ value: "spotlight_operator", label: "Spotlight operator" },
	{ value: "student", label: "Student" },
	{ value: "stunt_coordinator", label: "Stunt coordinator" },
	{ value: "stunt_performer", label: "Stunt performer" },
	{ value: "stylist", label: "Stylist" },
	{ value: "surveyor", label: "Surveyor" },
	{ value: "systems_analyst", label: "Systems analyst" },
	{ value: "tattoo_artist", label: "Tattoo artist" },
	{ value: "teacher", label: "Teacher" },
	{ value: "technical_director", label: "Technical director" },
	{ value: "technical_writer", label: "Technical writer" },
	{ value: "technician", label: "Technician" },
	{ value: "test_engineer", label: "Test engineer" },
	{ value: "theatre_manager", label: "Theatre manager" },
	{ value: "ticketing_agent", label: "Ticketing agent" },
	{ value: "urban_planner", label: "Urban planner" },
	{ value: "user_experience_designer", label: "User experience designer" },
	{ value: "user_interaction_designer", label: "User interaction designer" },
	{ value: "user_researcher", label: "User researcher" },
	{ value: "veterinarian", label: "Veterinarian" },
	{ value: "video_editor", label: "Video editor" },
	{ value: "videographer", label: "Videographer" },
	{ value: "visual_designer", label: "Visual designer" },
	{ value: "vocalist", label: "Vocalist" },
	{ value: "voice_actor", label: "Voice actor" },
	{ value: "volunteer", label: "Volunteer" },
	{ value: "waiter", label: "Waiter" },
	{ value: "wardrobe_supervisor", label: "Wardrobe supervisor" },
	{ value: "weatherperson", label: "Weatherperson" },
	{ value: "web_designer", label: "Web designer" },
	{ value: "web_developer", label: "Web developer" },
	{ value: "website_content_writer", label: "Website content writer" },
	{ value: "wedding_planner", label: "Wedding planner" },
	{ value: "wellness_coordinator", label: "Wellness coordinator" },
	{ value: "writer", label: "Writer" },
	{ value: "yoga_instructor", label: "Yoga instructor" },
	{ value: "zoologist", label: "Zoologist" },
]
