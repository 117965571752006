import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipFormSteps.module.scss"

import { useTranslation } from "react-i18next"
import { Outlet, useLocation } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"
import { AppExternalLinks } from "@constants/AppExternalLinks.ts"

import {
	MEMBERSHIP_STEP1_TABS,
	MEMBERSHIP_STEP2_TABS,
	MEMBERSHIP_STEP3_TABS,
} from "@pages/MembershipFormSteps/MembershipFormSteps.constants.ts"

import DsSteps from "@components/feedback/DsSteps/DsSteps.tsx"
import DvMembershipFormTabs from "@components/navigation/DvMembershipFormTabs/DvMembershipFormTabs.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"

const STEPS_WITHOUT_TABS = [4, 5, 6]

interface IProps {
	className?: string
	step: number
}

const MembershipFormSteps: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const location = useLocation()

	const currentKey = location.pathname.split("/")?.pop()

	let formStepTitle = t("your_basic_data")
	let selectedKey = MEMBERSHIP_STEP1_TABS.details
	let items: Antd.MenuProps["items"] = []
	let progressPart = 1

	switch (props.step) {
		case 1:
			formStepTitle = t("your_basic_data")
			selectedKey = currentKey || MEMBERSHIP_STEP1_TABS.details

			items = [
				{
					className: "first",
					key: MEMBERSHIP_STEP1_TABS.details,
					label: t("details"),
				},
				{
					key: MEMBERSHIP_STEP1_TABS.address,
					label: t("address"),
				},
				{
					key: MEMBERSHIP_STEP1_TABS.kindOfMembership,
					label: t("kind_of_membership"),
					className: "last",
				},
			]

			switch (location.pathname) {
				case AppRoutes.membershipFormStep1_details:
					progressPart = 0
					break
				case AppRoutes.membershipFormStep1_address:
					progressPart = 1
					break
				case AppRoutes.membershipFormStep1_kind_of_membership:
					progressPart = 2
					break
			}

			break

		case 2:
			selectedKey = currentKey || MEMBERSHIP_STEP2_TABS.personalDetails
			formStepTitle = t("personal_details")

			items = [
				{
					className: "first",
					key: MEMBERSHIP_STEP2_TABS.personalDetails,
					label: t("personal_details"),
				},
				{
					className: "last",
					key: MEMBERSHIP_STEP2_TABS.job,
					label: t("job"),
				},
			]

			switch (location.pathname) {
				case AppRoutes.membershipFormStep2_personalDetails:
					progressPart = 3
					break
				case AppRoutes.membershipFormStep2_job:
					progressPart = 4
					break
			}

			break
		case 3:
			selectedKey = currentKey || MEMBERSHIP_STEP3_TABS.presentation
			formStepTitle = t("about_you")

			items = [
				{
					className: "first",
					key: MEMBERSHIP_STEP3_TABS.presentation,
					label: t("presentation"),
				},
				{
					key: MEMBERSHIP_STEP3_TABS.digitalProfiles,
					label: t("digital_profiles"),
				},
				{
					className: "last",
					key: MEMBERSHIP_STEP3_TABS.references,
					label: t("references"),
				},
			]

			switch (location.pathname) {
				case AppRoutes.membershipFormStep3_presentation:
					progressPart = 5
					break
				case AppRoutes.membershipFormStep3_digital_profiles:
					progressPart = 6
					break
				case AppRoutes.membershipFormStep3_references:
					progressPart = 7
					break
			}

			break

		case 4:
			formStepTitle = t("globo_district_rules")
			progressPart = 8
			break
		case 5:
			formStepTitle = t("send_membership")
			progressPart = 9
			break
		case 6:
			formStepTitle = t("everything_is_ready")
			progressPart = 10
			break
	}

	return (
		<div
			className={`${styles.membership_form_steps} ${props.className || ""}`}
		>
			<div className={styles.header}>
				<DsSteps
					currentStep={props.step}
					totalSteps={MemberShipLayoutConstants.totalSteps - 1}
					percentage={(progressPart / 10) * 100}
				/>

				<h1 className={styles.title}>{formStepTitle}</h1>

				{!STEPS_WITHOUT_TABS.includes(props.step) && (
					<DvMembershipFormTabs
						selectedKeys={[selectedKey]}
						items={items}
					/>
				)}
			</div>

			{props.step !== 6 ? (
				<Outlet />
			) : (
				<>
					<DvAntdForm>
						<h1 className={styles.text}>
							{t("message_we_are_checking_your_application")}
						</h1>
					</DvAntdForm>

					<MembershipFormFooter
						backButtonHidden
						nextButtonTest={t("back_to_site")}
						onNextClick={() => {
							window.location.href = AppExternalLinks().globo_home
						}}
					/>
				</>
			)}
		</div>
	)
}

export default React.memo(MembershipFormSteps)
