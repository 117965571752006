import * as React from "react"
import styles from "./InfoDataCreateAccountReceived.module.scss"

import { useTranslation } from "react-i18next"
import { AppExternalLinks } from "@constants/AppExternalLinks.ts"

import DvBackground from "@components/layout/DvBackground/DvBackground.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"

interface IProps {
	className?: string
}

const InfoDataCreateAccountReceived: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	return (
		<div
			className={`${styles.info_data_create_account_received} ${props.className || ""}`}
		>
			<DvBackground />

			<div className={styles.message}>
				<div className={styles.header}>
					<h1 className={styles.title}>{t("thanks")}</h1>

					<h3 className={styles.sub_title}>
						{t("message_we_received_your_data")}
					</h3>

					<p className={styles.info}>
						{t("message_we_will_send_a_validation_email")}
					</p>
				</div>

				<div className={styles.footer}>
					<DsButton
						type="secondary"
						className={styles.action_button}
						onClick={() =>
							(window.location.href =
								AppExternalLinks().globo_home)
						}
					>
						{t("back_to_site")}
					</DsButton>
				</div>
			</div>
		</div>
	)
}

export default React.memo(InfoDataCreateAccountReceived)
