import * as React from "react"
import styles from "./DsLogoSDistrict.module.scss"

import logoSDistrict from "@assets/svg/logo-s-district.svg"

interface IProps {
	className?: string
	width?: React.CSSProperties["width"]
	height?: React.CSSProperties["height"]
}

const DsLogoSDistrict: React.FC<IProps> = (props) => {
	return (
		<img
			className={`${styles.ds_logo_s_district} ${props.className || ""}`}
			src={logoSDistrict}
			alt={"Logo S District"}
			style={{ width: props?.width, height: props?.height }}
		/>
	)
}

export default React.memo(DsLogoSDistrict)
