import * as React from "react"
import { useRef } from "react"
import { useTranslation } from "react-i18next"

import axios, { CancelTokenSource } from "axios"
import publicApi from "@others/public.ts"

interface IOptions {
	value: string
	label: string
}

const jsonName = "meet-us-options.json"

export const useListOfAllCountries = () => {
	const [options, setOptions] = React.useState<IOptions[]>([])
	const [loading, setLoading] = React.useState<boolean>(true)
	const { i18n } = useTranslation()

	const sourceRef = useRef<CancelTokenSource | null>(null)

	const fetchOptions = React.useCallback(() => {
		if (sourceRef.current) sourceRef.current.cancel()

		setLoading(true)

		sourceRef.current = axios.CancelToken.source()

		publicApi
			.get(`json/${i18n.language}/${jsonName}`, {
				cancelToken: sourceRef.current.token,
			})
			.then((response) => {
				setOptions(response.data)
			})
			.catch((error) => {
				if (!axios.isCancel(error)) setOptions([])
			})
			.finally(() => {
				setLoading(false)
			})
	}, [i18n.language])

	React.useEffect(() => {
		fetchOptions()

		return () => {
			if (sourceRef.current) sourceRef.current.cancel()
		}
	}, [fetchOptions])

	return {
		options,
		loading,
	}
}
