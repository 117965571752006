export const AppBusinessInfo = {
	companyEmail: "jsoto@elglobodistrict.com",
	infoEmail: "info@elglobodistrict.com",
	membershipEmail: "membresias@elglobodistrict.com",
	companyPhone: {
		countryCode: "598",
		number: "95280412",
		prettier: "+598 95 280 412",
	},
}
