import * as React from "react"

const mobile = 1024 // you also need to change the breakpoints.scss file

interface ICtx {
	isMobile: boolean
	isDesktop: boolean
}

const InitialCtxState: ICtx = {
	isMobile: window.innerWidth <= mobile,
	isDesktop: window.innerWidth > mobile,
}

export const Context = React.createContext<ICtx>(InitialCtxState)

const CtxProvider: React.FC<{
	children: React.ReactNode
}> = ({ children }) => {
	const [isMobile, setIsMobile] = React.useState<ICtx["isMobile"]>(
		InitialCtxState.isMobile
	)

	const [isDesktop, setIsDesktop] = React.useState<ICtx["isDesktop"]>(
		InitialCtxState.isDesktop
	)

	React.useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= mobile)
			setIsDesktop(window.innerWidth > mobile)
		}

		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	const contextValue = React.useMemo(
		() => ({
			isMobile,
			isDesktop,
		}),
		[isMobile, isDesktop]
	)

	return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export const ResponsiveToolCtxProvider = CtxProvider
