import * as React from "react"
import * as Antd from "antd"
import styles from "./DsSteps.module.scss"

import { useTranslation } from "react-i18next"

interface IProps {
	className?: string
	currentStep: number
	totalSteps: number
	percentage: number
}

const DsSteps: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	const isProcessFinished = props.percentage === 100

	return (
		<div className={`${styles.ds_steps} ${props.className || ""}`}>
			<h1 className={styles.text}>
				{isProcessFinished
					? t("process_finished")
					: `${t("step")} ${props.currentStep} ${t("of")} ${props.totalSteps}`}
			</h1>

			<Antd.Progress
				className={`${styles.progress_bar} ${isProcessFinished ? styles.full : ""}`}
				percent={props.percentage}
				showInfo={false}
			/>
		</div>
	)
}

export default React.memo(DsSteps)
