import * as React from "react"
import * as Antd from "antd"
import * as AntdIcons from "@ant-design/icons"
import styles from "./DvHamburgerButton.module.scss"

interface IProps extends Antd.ButtonProps {
	className?: string
	isOpened?: boolean
}

const DvHamburgerButton: React.FC<IProps> = ({ isOpened, ...props }) => {
	return (
		<Antd.Button
			{...props}
			className={`${styles.dv_hamburger_button} ${props.className || ""}`}
			icon={
				isOpened ? (
					<AntdIcons.CloseOutlined className={styles.icon} />
				) : (
					<AntdIcons.MenuOutlined className={styles.icon} />
				)
			}
		/>
	)
}

export default React.memo(DvHamburgerButton)
