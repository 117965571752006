import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipDigitalProfilesForm.module.scss"

import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useTranslation } from "react-i18next"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"

export interface IMembershipDigitalProfilesFormFieldType {
	instagram?: string
	twitter?: string
	linkedIn?: string
}

interface IProps {
	className?: string
}

const MembershipDigitalProfilesForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const [form] = Antd.Form.useForm<IMembershipDigitalProfilesFormFieldType>()
	const { t } = useTranslation()

	const initialValues: IMembershipDigitalProfilesFormFieldType =
		React.useMemo(
			() => ({
				instagram: undefined,
				twitter: undefined,
				linkedIn: undefined,
			}),
			[]
		)

	const { onChange } = useMembershipApplyForm(form, initialValues)

	const onFinish = async ({
		twitter,
		linkedIn,
		instagram,
	}: IMembershipDigitalProfilesFormFieldType) => {
		await onChange("twitter", twitter)
		await onChange("linkedIn", linkedIn)
		await onChange("instagram", instagram)

		navigate(AppRoutes.membershipFormStep3_references, {
			replace: true,
		})
	}

	return (
		<>
			<DvAntdForm<IMembershipDigitalProfilesFormFieldType>
				form={form}
				className={`${styles.membership_digital_profile_form} ${
					props.className || ""
				}`}
				initialValues={initialValues}
				onFinish={onFinish}
			>
				<header className={styles.header}>
					{t("message_we_can_meet_you_in_social_media")}:
				</header>

				<DvAntdFormItem<IMembershipDigitalProfilesFormFieldType>
					label="Instagram"
					name="instagram"
				>
					<DvInput
						placeholder={t("insert_you_user")}
						onChange={(e) => onChange("instagram", e.target.value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipDigitalProfilesFormFieldType>
					label="X (Twitter)"
					name="twitter"
				>
					<DvInput
						placeholder={t("insert_you_user")}
						onChange={(e) => onChange("twitter", e.target.value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipDigitalProfilesFormFieldType>
					label="LinkedIn"
					name="linkedIn"
				>
					<DvInput
						placeholder={t("insert_you_user")}
						onChange={(e) => onChange("linkedIn", e.target.value)}
					/>
				</DvAntdFormItem>

				<DsButton
					id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
					style={{ display: "none" }}
					htmlType="submit"
				/>
			</DvAntdForm>

			<MembershipFormFooter
				onBackClick={() =>
					navigate(AppRoutes.membershipFormStep3_presentation)
				}
				onNextClick={() => form.submit()}
			/>
		</>
	)
}

export default React.memo(MembershipDigitalProfilesForm)
