import React from "react"
import styles from "./Home.module.scss"

import { Link } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useTranslation } from "react-i18next"

interface IProps {
	className?: string
}

const Home: React.FC<IProps> = (props) => {
	const { t, i18n } = useTranslation()

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
	}

	return (
		<div className={`${styles.home} ${props.className || ""}`}>
			<h1>{t("welcome")}</h1>
			<p>{t("description")}</p>

			<button onClick={() => changeLanguage("en")}>English</button>
			<button onClick={() => changeLanguage("es")}>Español</button>

			<Link to={AppRoutes.login}>Login</Link>
		</div>
	)
}

export default React.memo(Home)
