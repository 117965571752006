import api from "@others/api"

import { IApiResponse } from "@interfaces/generic/IApiResponse"
import { Endpoints } from "@constants/Endpoints.tsx"
import { AppFunctions } from "@others/AppFunctions.ts"
import { IPostMembershipApplyBodyApi } from "@interfaces/IPostMembershipApplyBodyApi.ts"
import { IPostMembershipApplyResponseApi } from "@interfaces/IPostMembershipApplyResponseApi.ts"

const apply = async (
	body: IPostMembershipApplyBodyApi
): Promise<IPostMembershipApplyResponseApi> => {
	return api
		.post<IApiResponse<IPostMembershipApplyResponseApi>>(
			Endpoints.applyMembership,
			body
		)
		.then((response) => {
			const { data } = response.data
			return data
		})
		.catch((error) => {
			throw new Error(AppFunctions.getErrorApiDetail(error))
		})
}

export default { apply }
