import React from "react"

import { Navigate, RouteProps, useLocation } from "react-router-dom"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"

const ProtectedRoute: React.FC<RouteProps> = ({ children }) => {
	const isAuthenticated = !!localStorage.getItem(AppLocalStorageKeys.TOKEN)
	const location = useLocation()
	const currentPath = location.pathname + location.search

	if (!isAuthenticated) {
		return <Navigate to={AppRoutes.login} state={{ from: currentPath }} />
	}

	return <>{children}</>
}

export default ProtectedRoute
