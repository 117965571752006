import axios from "axios"
import axiosRetry from "axios-retry"

import { AppRoutes } from "@constants/AppRoutes.ts"

const publicApi = axios.create({
	baseURL: AppRoutes.public,
	timeout: 10000,
	headers: {
		"Content-Type": "application/json",
	},
})

axiosRetry(publicApi, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

export default publicApi
