import * as React from "react"
import styles from "./DsSpace.module.scss"

interface IProps {
	amountOfPlans: number
}

const DsSpace: React.FC<IProps> = ({ amountOfPlans }) => {
	return (
		<>
			{new Array(amountOfPlans + 1).fill(0).map((_, idx) => (
				<div className={styles.space} key={idx} />
			))}
		</>
	)
}

export default React.memo(DsSpace)
