import { useTranslation } from "react-i18next"
import { AppLanguages } from "@constants/AppLanguages.ts"
import { useMemo } from "react"
import { ListJobRolesEn, ListJobRolesEs } from "@constants/ListJobRoles.ts"

export const useListJobRoles = () => {
	const { i18n } = useTranslation()

	const jobRoles = useMemo(() => {
		const kindOfJobsRaw =
			i18n.language === AppLanguages.SPANISH
				? ListJobRolesEs
				: ListJobRolesEn

		return kindOfJobsRaw.sort((a, b) => {
			if (a.label < b.label) {
				return -1
			}
			if (a.label > b.label) {
				return 1
			}
			return 0
		})
	}, [i18n.language])

	return {
		jobRoles,
	}
}
