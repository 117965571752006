import { Route } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"

import ProtectedRoute from "@components/others/PrivateRoute/PrivateRoute.tsx"
import UserProfile from "@pages/UserProfile/UserProfile.tsx"
import UserSettings from "@pages/UserSettings/UserSettings.tsx"
import PersonalDataForm from "@components/dataEntry/PersonalDataForm/PersonalDataForm.tsx"

const UserRoutes = () => {
	return (
		<Route path={AppRoutes.user}>
			<Route
				path={AppRoutes.userProfile}
				element={
					<ProtectedRoute>
						<UserProfile />
					</ProtectedRoute>
				}
			/>

			<Route
				path={AppRoutes.userSettings}
				element={
					<ProtectedRoute>
						<UserSettings />
					</ProtectedRoute>
				}
			/>

			<Route
				path={AppRoutes.userPersonalDataForm}
				element={
					<ProtectedRoute>
						<PersonalDataForm />
					</ProtectedRoute>
				}
			/>
		</Route>
	)
}

export default UserRoutes
