import * as React from "react"
import bgImage from "@assets/svg/bg-1.svg"
import styles from "./DvBackground.module.scss"

import AppImage from "@components/others/AppImage/AppImage.tsx"

interface IProps {
	className?: string
}

const DvBackground: React.FC<IProps> = (props) => {
	return (
		<div className={`${styles.dv_background} ${props.className || ""}`}>
			<AppImage className={styles.image} src={bgImage} alt={"bg-1"} />
		</div>
	)
}

export default React.memo(DvBackground)
