import * as React from "react"
import * as Antd from "antd"
import styles from "./DvMembershipFormTabs.module.scss"

interface IProps extends Antd.MenuProps {
	className?: string
}

const DvMembershipFormTabs: React.FC<IProps> = ({ ...props }) => {
	return (
		<Antd.Menu
			{...props}
			className={`${styles.dv_membership_form_tabs} ${props.className || ""}`}
			rootClassName={styles.popup_menu}
			theme="dark"
			mode="horizontal"
			style={{
				flex: "1 1 auto",
				overflow: "visible",
				minWidth: 0,
			}}
		/>
	)
}

export default React.memo(DvMembershipFormTabs)
