import * as React from "react"
import * as AntdIcons from "@ant-design/icons"
import styles from "./DsPlanTable.module.scss"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { IPlanData } from "@hooks/usePlanData.tsx"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DsPlanPrice from "@components/dataDisplay/DsPlanTable/DsPlanPrice/DsPlanPrice.tsx"
import DsSpace from "@components/dataDisplay/DsPlanTable/DsSpace/DsSpace.tsx"

const Table = "div",
	HeaderCell = "div",
	ItemCell = "div"

interface IColumn {
	planName: string
	planType: string
}

interface IRow {
	benefitName: string
	benefitsEnabled: boolean[]
}

interface IBilling {
	id: string
	name: string
	monthlyBill: {
		frequency: string
		price: number
		currency: string
		description: string
	}
	annualBill: {
		frequency: string
		price: number
		currency: string
		description: string
	}
}

export interface IPlanTableFormFieldType {
	plan?: IPlanData["plans"][0]["id"]
	paymentFrequency?:
		| IPlanData["plans"][0]["monthlyBill"]["frequency"]
		| IPlanData["plans"][0]["annualBill"]["frequency"]
}

export interface IDsPlanTableProps {
	className?: string
	columns: IColumn[]
	rows: IRow[]
	billing: IBilling[]
	defaultPaymentFrequency: IPlanTableFormFieldType["paymentFrequency"]
	defaultPlan: IPlanTableFormFieldType["plan"]
}

const DsPlanTable: React.FC<IDsPlanTableProps> = (props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const initialValues = React.useMemo<IPlanTableFormFieldType>(
		() => ({
			plan: props.defaultPlan,
			paymentFrequency: props.defaultPaymentFrequency,
		}),
		[props.defaultPlan, props.defaultPaymentFrequency]
	)

	const { states, onChange } = useMembershipApplyForm(
		undefined,
		initialValues
	)

	return (
		<Table className={`${styles.ds_plan_table} ${props.className || ""}`}>
			<HeaderCell className={styles.header_col_1}>
				{t("benefit").toUpperCase()}
			</HeaderCell>

			{props.columns.map(({ planName, planType }, idx) => {
				const className = `header_col_${idx + 2}`
				return (
					<HeaderCell className={styles[className]} key={idx}>
						<h1>{planName}</h1>
						<h3>{planType}</h3>
					</HeaderCell>
				)
			})}

			<DsSpace amountOfPlans={props.columns.length} />

			{props.rows.map(({ benefitName, benefitsEnabled }, idx) => (
				<React.Fragment key={idx}>
					<ItemCell className={styles.item_col_1}>
						{benefitName}
					</ItemCell>

					{benefitsEnabled.map((enabled, idx) => {
						const className = `item_col_${idx + 2}`
						return (
							<ItemCell className={styles[className]} key={idx}>
								{enabled ? (
									<AntdIcons.CheckOutlined
										className={styles.check_icon}
									/>
								) : (
									<AntdIcons.CloseOutlined
										className={styles.close_icon}
									/>
								)}
							</ItemCell>
						)
					})}
				</React.Fragment>
			))}

			<DsSpace amountOfPlans={props.columns.length} />

			<ItemCell className={styles.footer_col_1}>
				<DsButton
					className={`${styles.payment_button} ${states.paymentFrequency !== "monthly" && styles.disabled}`}
					onClick={async () =>
						onChange("paymentFrequency", "monthly")
					}
				>
					{t("monthly")}
				</DsButton>

				<DsButton
					className={`${styles.payment_button} ${states.paymentFrequency !== "annual" && styles.disabled}`}
					onClick={async () => onChange("paymentFrequency", "annual")}
				>
					{t("annual")}
				</DsButton>
			</ItemCell>

			{props.billing.map(({ monthlyBill, annualBill, id }, idx) => {
				const className = `footer_col_${idx + 2}`

				const { frequency, price, currency, description } =
					states.paymentFrequency === "monthly"
						? monthlyBill
						: annualBill

				return (
					<ItemCell className={styles[className]} key={idx}>
						<DsPlanPrice
							selected={states.plan === id}
							frequency={t(frequency)}
							price={price}
							currency={currency}
							description={description}
							onClick={async () => {
								await onChange(
									"plan",
									id as IPlanTableFormFieldType["plan"]
								)

								await onChange(
									"paymentFrequency",
									states.paymentFrequency
								)

								navigate(AppRoutes.membershipStartApplying, {
									replace: true,
								})
							}}
						/>
					</ItemCell>
				)
			})}
		</Table>
	)
}

export default React.memo(DsPlanTable)
