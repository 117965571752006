import i18n from "i18next"
import esTranslation from "./es/translation.json"
import enTranslation from "./en/translation.json"

import { initReactI18next } from "react-i18next"
import { AppLanguages } from "@constants/AppLanguages.ts"

import HttpBackend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

i18n.use(HttpBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: { translation: enTranslation },
			es: { translation: esTranslation },
		},
		fallbackLng: AppLanguages.DEFAULT,
		debug: false,
		supportedLngs: [AppLanguages.ENGLISH, AppLanguages.SPANISH],
		interpolation: {
			escapeValue: false,
		},
		detection: {
			order: [
				"querystring",
				"cookie",
				"localStorage",
				"navigator",
				"htmlTag",
			],
			caches: ["cookie", "localStorage"],
		},
	})

export default i18n
