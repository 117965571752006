export interface IKindOfJobs {
	label: string
	value: string
}

export const ListKindOfJobsEs: IKindOfJobs[] = [
	{ value: "foods_and_drinks", label: "Alimentos y bebidas" },
	{ value: "architecture", label: "Arquitectura" },
	{ value: "floral_arrangements", label: "Arreglos florales" },
	{ value: "art", label: "Arte" },
	{ value: "performing_arts", label: "Artes escénicas" },
	{ value: "aviation", label: "Aviación" },
	{ value: "science", label: "Ciencia" },
	{ value: "cinema", label: "Cine" },
	{ value: "construction", label: "Construcción" },
	{ value: "contracting", label: "Contratación" },
	{ value: "healthcare", label: "Cuidados de salud" },
	{ value: "digital", label: "Digital" },
	{ value: "interior_design", label: "Diseño de interiores" },
	{ value: "graphic_design", label: "Diseño gráfico" },
	{ value: "editorial", label: "Editorial" },
	{ value: "education", label: "Educación" },
	{ value: "energy", label: "Energía" },
	{ value: "beauty", label: "Estética o belleza" },
	{ value: "events", label: "Eventos" },
	{ value: "philanthropy", label: "Filantropía" },
	{ value: "finances", label: "Finanzas" },
	{ value: "photography", label: "Fotografía" },
	{ value: "public_official", label: "Funcionario" },
	{ value: "hospitality", label: "Hostelería" },
	{ value: "jewelry", label: "Joyería" },
	{ value: "legal", label: "Jurídico" },
	{ value: "literature", label: "Literatura" },
	{ value: "manufacturing", label: "Manufactura" },
	{
		value: "marketing_advertising_brand",
		label: "Marketing/Publicidad/Marca",
	},
	{ value: "environmental", label: "Medioambiental" },
	{ value: "television_media", label: "Medios televisivos" },
	{ value: "real_estate_market", label: "Mercado inmobiliario" },
	{ value: "fashion", label: "Moda" },
	{ value: "music", label: "Música" },
	{ value: "public_relations", label: "Relaciones públicas" },
	{ value: "health_wellness", label: "Salud y bienestar" },
	{ value: "real_estate_sector", label: "Sector inmobiliario" },
	{ value: "technology", label: "Tecnología" },
	{ value: "sales", label: "Venta" },
]

export const ListKindOfJobsEn: IKindOfJobs[] = [
	{ value: "foods_and_drinks", label: "Foods and drinks" },
	{ value: "architecture", label: "Architecture" },
	{ value: "floral_arrangements", label: "Floral arrangements" },
	{ value: "art", label: "Art" },
	{ value: "performing_arts", label: "Performing arts" },
	{ value: "aviation", label: "Aviation" },
	{ value: "science", label: "Science" },
	{ value: "cinema", label: "Cinema" },
	{ value: "construction", label: "Construction" },
	{ value: "contracting", label: "Contracting" },
	{ value: "healthcare", label: "Healthcare" },
	{ value: "digital", label: "Digital" },
	{ value: "interior_design", label: "Interior design" },
	{ value: "graphic_design", label: "Graphic design" },
	{ value: "editorial", label: "Editorial" },
	{ value: "education", label: "Education" },
	{ value: "energy", label: "Energy" },
	{ value: "beauty", label: "Beauty" },
	{ value: "events", label: "Events" },
	{ value: "philanthropy", label: "Philanthropy" },
	{ value: "finances", label: "Finances" },
	{ value: "photography", label: "Photography" },
	{ value: "public_official", label: "Public official" },
	{ value: "hospitality", label: "Hospitality" },
	{ value: "jewelry", label: "Jewelry" },
	{ value: "legal", label: "Legal" },
	{ value: "literature", label: "Literature" },
	{ value: "manufacturing", label: "Manufacturing" },
	{
		value: "marketing_advertising_brand",
		label: "Marketing/Advertising/Brand",
	},
	{ value: "environmental", label: "Environmental" },
	{ value: "television_media", label: "Television media" },
	{ value: "real_estate_market", label: "Real estate market" },
	{ value: "fashion", label: "Fashion" },
	{ value: "music", label: "Music" },
	{ value: "public_relations", label: "Public relations" },
	{ value: "health_wellness", label: "Health and wellness" },
	{ value: "real_estate_sector", label: "Real estate sector" },
	{ value: "technology", label: "Technology" },
	{ value: "sales", label: "Sales" },
]
