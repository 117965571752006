import * as React from "react"
import styles from "./DsLogoBlancoElGlobo.module.scss"

import logoBlancoElGloboHorizontal from "@assets/svg/logo-blanco-el-globo-horizontal.svg"
import logoBlancoElGloboVertical from "@assets/svg/logo-blanco-el-globo-vertical.svg"

import { AppExternalLinks } from "@constants/AppExternalLinks.ts"

interface IProps {
	className?: string
	type: "horizontal" | "vertical"
	width?: React.CSSProperties["width"]
	height?: React.CSSProperties["height"]
}

const DsLogoBlancoElGlobo: React.FC<IProps> = (props) => {
	const goGloboHome = () => {
		window.location.href = AppExternalLinks().globo_home
	}

	return (
		<img
			className={`${styles.ds_logo_blanco_el_globo} ${props.className || ""} desktop`}
			onClick={goGloboHome}
			style={{ width: props?.width, height: props?.height }}
			alt={"Logo Blanco El Globo"}
			src={
				props.type === "horizontal"
					? logoBlancoElGloboHorizontal
					: logoBlancoElGloboVertical
			}
		/>
	)
}

export default React.memo(DsLogoBlancoElGlobo)
