import React from "react"

import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { AppRoutes } from "./constants/AppRoutes.ts"
import { configureDayJs } from "../dayjsConfig.ts"
import { useTranslation } from "react-i18next"

import Home from "@pages/Home/Home.tsx"
import NotFound from "@pages/NotFound/NotFound.tsx"
import AppLayout from "@components/layout/AppLayout/AppLayout.tsx"
import MembershipRoutes from "@routes/MembershipRoutes.tsx"
import InfoRoutes from "@routes/InfoRoutes.tsx"
import loginRoutes from "@routes/LoginRoutes.tsx"
import UserRoutes from "@routes/UserRoutes.tsx"

interface IProps {
	className?: string
}

const App: React.FC<IProps> = () => {
	const { i18n } = useTranslation()

	configureDayJs(i18n.language)

	return (
		<Router>
			<Routes>
				<Route path={AppRoutes.home} element={<AppLayout />}>
					<Route index element={<Home />} />

					{loginRoutes()}

					{MembershipRoutes()}

					{InfoRoutes()}

					{UserRoutes()}

					<Route path="*" element={<NotFound />} />
				</Route>
			</Routes>
		</Router>
	)
}

export default App
