import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipSendForm.module.scss"

import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AppMessage } from "@others/AppMessage.ts"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"

import membershipService from "@services/membershipService.ts"
import useMembershipLayoutCtx from "@contexts/hooks/useMembershipLayoutCtx.tsx"
import useAuth from "@contexts/hooks/useAuth.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import membershipFormDataService from "@services/membershipApplyFormService.ts"

interface IProps {
	className?: string
}

const MembershipGloboRulesForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const [form] = Antd.Form.useForm()
	const { t } = useTranslation()
	const { nextButtonLoading, setNextButtonLoading } = useMembershipLayoutCtx()
	const { setLoggedUser } = useAuth()

	const { clearForm } = useMembershipApplyForm(form)

	const onFinish = async () => {
		setNextButtonLoading(true)

		const formData = await membershipFormDataService.getFormData()

		membershipService
			.apply({
				...formData,
				plan: formData.plan!,
				paymentFrequency: formData.paymentFrequency!,
				name: formData.name!,
				lastName: formData.lastName!,
				email: formData.email!,
				birthdate: formData.birthdate!,
				country: formData.country!,
				city: formData.city!,
				address_1: formData.address_1!,
				zipCode: formData.zipCode!,
				phone: formData.phone!,
				nationality: formData.nationality!,
				gender: formData.gender!,
				receivePromotionsByWhatsapp:
					formData.receivePromotionsByWhatsapp!,
				nationalId: formData.nationalId!,
				job: formData.job!,
				kindOfJob: formData.kindOfJob!,
				role: formData.role!,
				workEmail: formData.workEmail!,
				presentation: formData.presentation!,
				globoRulesAccepted: formData.globoRulesAccepted!,
				receivePromotions: formData.receivePromotions!,
			})
			.then(async (response) => {
				if (response.membership_created) {
					setLoggedUser((loggedUser) => {
						return {
							...loggedUser,
							hasMembershipInProgress: true,
							profilePhoto: formData.profilePhoto,
						}
					})

					await clearForm()

					navigate(AppRoutes.membershipSuccess, {
						replace: true,
					})
				}
			})
			.catch(({ message }) => {
				AppMessage.error(message || t("something_went_wrong"))
			})
			.finally(() => {
				setNextButtonLoading(false)
			})
	}

	return (
		<>
			<DvAntdForm
				form={form}
				className={`${styles.membership_globo_rules_form} ${
					props.className || ""
				}`}
				onFinish={onFinish}
			>
				<h1 className={styles.text}>
					{t("message_thanks_for_your_application")}
				</h1>
			</DvAntdForm>

			<MembershipFormFooter
				nextButtonTest={t("send")}
				nextButtonLoading={nextButtonLoading}
				onBackClick={() =>
					navigate(AppRoutes.membershipFormStep4_globoRules)
				}
				onNextClick={() => form.submit()}
			/>
		</>
	)
}

export default React.memo(MembershipGloboRulesForm)
