import { ILoginFormFieldType } from "@components/dataEntry/LoginForm/LoginForm.tsx"
import { generateAppEntity } from "@others/generateAppEntity.ts"
import indexedDBService from "@services/indexedDBService.ts"

const prefixKey = generateAppEntity("loginFormData_")

const setKey = (
	name: keyof ILoginFormFieldType,
	value: ILoginFormFieldType[typeof name]
) => {
	return indexedDBService.addKey(`${prefixKey}${name}`, value)
}

const getFormData = async (): Promise<ILoginFormFieldType> => {
	return indexedDBService.getKeysWithPrefix<ILoginFormFieldType>(prefixKey)
}

const clearFormData = () => {
	return indexedDBService.removeKeysWithPrefix(prefixKey)
}

export default { setKey, getFormData, clearFormData }
