import * as React from "react"
import * as Antd from "antd"
import styles from "./DsMenuDropDown.module.scss"

import { useTranslation } from "react-i18next"

import { AppExternalLinks } from "@constants/AppExternalLinks.ts"
import { AppLanguages } from "@constants/AppLanguages.ts"

interface IProps extends Antd.DropdownProps {
	className?: string
}

const DsMenuDropDown: React.FC<IProps> = (props) => {
	const { t, i18n } = useTranslation()
	const externalLinks = AppExternalLinks()
	const [language, setLanguage] = React.useState<string>(i18n.language)

	const handleLanguageChange = (language: string) => {
		i18n.changeLanguage(language)
		setLanguage(language)
	}

	const inEnglish = language === AppLanguages.ENGLISH
	const inSpanish = language === AppLanguages.SPANISH

	const items: Antd.MenuProps["items"] = [
		{
			className: styles.drop_down_item,
			key: 1,
			label: <a href={externalLinks.aboutUs}>{t("about_us")}</a>,
		},
		{
			className: styles.drop_down_item,
			key: 2,
			label: <a href={externalLinks.theProject}>{t("the_project")}</a>,
		},

		{
			className: styles.drop_down_item,
			key: 3,
			label: <a href={externalLinks.invest}>{t("invest")}</a>,
		},
		{
			className: styles.drop_down_item,

			key: 4,
			label: <a href={externalLinks.press}>{t("press")}</a>,
		},
		{
			className: styles.drop_down_item,

			key: 5,
			label: <a href={externalLinks.impact}>{t("impact")}</a>,
		},
		{
			className: styles.drop_down_item,

			key: 6,
			label: <a href={externalLinks.contact}>{t("contact")}</a>,
		},
		{
			className: styles.drop_down_item,

			key: 7,
			label: <a href={externalLinks.membership}>{t("membership")}</a>,
		},
		{
			className: `${styles.drop_down_item} ${inEnglish ? styles.language_selector_item_selected : ""}`,
			key: 8,
			label: "EN",
			onClick: () => handleLanguageChange(AppLanguages.ENGLISH),
		},
		{
			className: `${styles.drop_down_item} ${inSpanish ? styles.language_selector_item_selected : ""}`,
			key: 9,
			label: "ES",
			onClick: () => handleLanguageChange(AppLanguages.SPANISH),
		},
	]

	return (
		<Antd.Dropdown
			{...props}
			className={`${styles.ds_menu_drop_down} ${props.className || ""}`}
			overlayClassName={styles.overlay}
			menu={{ items }}
			trigger={["click"]}
		>
			{props.children}
		</Antd.Dropdown>
	)
}

export default React.memo(DsMenuDropDown)
