import * as React from "react"
import styles from "./MembershipFormFooter.module.scss"

import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"
import { useTranslation } from "react-i18next"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface IProps {
	className?: string
	backButtonTest?: string
	nextButtonTest?: string
	onNextClick?: () => void
	onBackClick?: () => void
	backButtonHidden?: boolean
	nextButtonHidden?: boolean
	backButtonLoading?: boolean
	nextButtonLoading?: boolean
}

const MembershipFormFooter: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { isDesktop } = useResponsiveToolCtx()

	return (
		<footer
			className={`${styles.membership_form_footer} ${props.className || ""}`}
			style={{ display: isDesktop ? "flex" : "none" }}
		>
			{!props.backButtonHidden && (
				<DsButton
					loading={props.backButtonLoading}
					className={styles.login_button}
					onClick={props.onBackClick}
					type="secondary"
				>
					{props?.backButtonTest || t("back")}
				</DsButton>
			)}

			{!props.nextButtonHidden && (
				<DsButton
					id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
					loading={props.nextButtonLoading}
					className={styles.login_button}
					onClick={props.onNextClick}
					htmlType="submit"
				>
					{props?.nextButtonTest || t("continue")}
				</DsButton>
			)}
		</footer>
	)
}

export default React.memo(MembershipFormFooter)
