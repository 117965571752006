import * as React from "react"
import styles from "./ValidateAccountForm.module.scss"

import { useTranslation } from "react-i18next"
import { Navigate, useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppMessage } from "@others/AppMessage.ts"
import { AppFunctions } from "@others/AppFunctions.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import authService from "@services/authService.ts"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import useAuthCtx from "@contexts/hooks/useAuth.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import validationRules from "@others/validationRules.ts"

type FieldType = {
	email?: string
}

interface IProps {
	className?: string
}

const ValidateAccountForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { isLogged, loggedUser } = useAuthCtx()

	const [isCheckingEmail, setIsCheckingEmail] = React.useState(false)

	const plan = AppFunctions.getUrlParam("plan")
	const paymentFrequency = AppFunctions.getUrlParam("paymentFrequency")

	if (plan && paymentFrequency && isLogged && loggedUser) {
		if (
			!loggedUser?.hasAnApprovedMembership &&
			!loggedUser?.hasMembershipInProgress
		) {
			return (
				<Navigate
					to={AppRoutes.membershipStartApplying}
					replace
					state={{ plan, paymentFrequency }}
				/>
			)
		} else if (loggedUser?.hasAnApprovedMembership) {
			return (
				<Navigate
					to={AppRoutes.userProfile}
					replace
					state={{ hasAnApprovedMembership: true }}
				/>
			)
		} else if (loggedUser?.hasMembershipInProgress) {
			return (
				<Navigate
					to={AppRoutes.userProfile}
					replace
					state={{ hasMembershipInProgress: true }}
				/>
			)
		}
	}

	const onFinish = ({ email }: FieldType) => {
		setIsCheckingEmail(true)

		authService
			.checkEmail({ email })
			.then((response) => {
				if (response.accountExists) {
					AppMessage.success(t("message_account_exists"))
					navigate(AppRoutes.login, {
						replace: true,
						state: { email, plan, paymentFrequency },
					})
				} else {
					navigate(AppRoutes.signup, {
						replace: true,
						state: { email, plan, paymentFrequency },
					})
				}
			})
			.catch(({ message }) => {
				AppMessage.error(message || t("something_went_wrong"))
			})
			.finally(() => {
				setIsCheckingEmail(false)
			})
	}

	return (
		<DvAntdForm
			className={`${styles.validate_account_form} ${props.className || ""}`}
			name="basic"
			initialValues={{ remember: true }}
			onFinish={onFinish}
		>
			<div className={styles.top}>
				<header className={styles.header}>
					{t("instructions_to_validate_account")}
				</header>

				<main className={styles.body}>
					<DvAntdFormItem<FieldType>
						className={styles.form_item}
						label={t("email")}
						name="email"
						validateDebounce={1500}
						rules={[
							validationRules.requiredFieldRule(
								t("please_enter_your_email")
							),
							validationRules.emailRule(
								t("your_email_is_not_valid")
							),
						]}
					>
						<DvInput placeholder={t("insert_your_email")} />
					</DvAntdFormItem>
				</main>
			</div>

			<div className={styles.footer}>
				<DsButton
					className={styles.action_button}
					htmlType="submit"
					loading={isCheckingEmail}
				>
					{t("continue")}
				</DsButton>

				<DsButton
					type="secondary"
					className={styles.action_button}
					onClick={() => {
						navigate(AppRoutes.login, { replace: true })
					}}
				>
					{t("back")}
				</DsButton>
			</div>
		</DvAntdForm>
	)
}

export default React.memo(ValidateAccountForm)
