import * as React from "react"
import * as Antd from "antd"
import { InputProps } from "antd"
import styles from "./DvInput.module.scss"
import { PasswordProps, TextAreaProps } from "antd/es/input"
import { TextAreaRef } from "antd/es/input/TextArea"

const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
	if (e?.key === "Unidentified") {
		setTimeout(() => {
			const input = e.target as HTMLInputElement
			input.value += " "

			setTimeout(() => {
				input.value = input.value.trim()
			}, 1)
		}, 1)
	}
}

const AntdInputTextArea = React.forwardRef<TextAreaRef, TextAreaProps>(
	(props, ref) => {
		return (
			<Antd.Input.TextArea
				{...props}
				className={`${styles.dv_input} ${props.className}`}
				ref={ref}
			/>
		)
	}
)

const AntdInputPassword = React.forwardRef<Antd.InputRef, PasswordProps>(
	(props, ref) => {
		return (
			<Antd.Input.Password
				{...props}
				className={`${styles.dv_input} ${props.className}`}
				onKeyDown={keyDownHandler}
				ref={ref}
			/>
		)
	}
)

const AntdInput = React.forwardRef<Antd.InputRef, InputProps>((props, ref) => {
	return (
		<Antd.Input
			{...props}
			className={`${styles.dv_input} ${props.className}`}
			onKeyDown={keyDownHandler}
			ref={ref}
		/>
	)
})

interface IDvInput
	extends React.ForwardRefExoticComponent<
		Antd.InputProps & React.RefAttributes<Antd.InputRef>
	> {
	TextArea: React.ForwardRefExoticComponent<
		TextAreaProps & React.RefAttributes<TextAreaRef>
	>
	Password: React.ForwardRefExoticComponent<
		PasswordProps & React.RefAttributes<Antd.InputRef>
	>
}

const DvInput = AntdInput as unknown as IDvInput

DvInput.TextArea = AntdInputTextArea
DvInput.Password = AntdInputPassword

export default DvInput
