import * as React from "react"
import styles from "./PhotoUploadFormItem.module.scss"

import { useTranslation } from "react-i18next"

interface IProps {
	className?: string
	children?: React.ReactNode
}

const PhotoUploadFormItem: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	return (
		<div
			className={`${styles.photo_upload_form_item} ${props.className || ""}`}
		>
			<h1 className={styles.image_header}>
				{t("message_upload_you_photo_to_inter_in")}{" "}
				<strong>Distrito El Globo</strong>.
			</h1>

			<div className={styles.image_container}>{props.children}</div>
		</div>
	)
}

export default React.memo(PhotoUploadFormItem)
