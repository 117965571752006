import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipPresentationForm.module.scss"

import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useTranslation } from "react-i18next"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"

import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import validationRules from "@others/validationRules.ts"

export interface IMembershipPresentationFormFieldType {
	presentation?: string
}

interface IProps {
	className?: string
}

const MembershipPresentationForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const [form] = Antd.Form.useForm<IMembershipPresentationFormFieldType>()
	const { t } = useTranslation()

	const initialValues: IMembershipPresentationFormFieldType = React.useMemo(
		() => ({
			presentation: undefined,
		}),
		[]
	)

	const { onChange } = useMembershipApplyForm(form, initialValues)

	const onFinish = async ({
		presentation,
	}: IMembershipPresentationFormFieldType) => {
		await onChange("presentation", presentation)

		navigate(AppRoutes.membershipFormStep3_digital_profiles, {
			replace: true,
		})
	}

	return (
		<>
			<DvAntdForm<IMembershipPresentationFormFieldType>
				form={form}
				className={`${styles.membership_presentation_form} ${
					props.className || ""
				}`}
				name="basic"
				initialValues={initialValues}
				onFinish={onFinish}
				autoComplete="off"
				layout="vertical"
			>
				<DvAntdFormItem<IMembershipPresentationFormFieldType>
					className={styles.form_item}
					label={t("message_presentation")}
					name="presentation"
					rules={[
						validationRules.requiredFieldRule(
							t("please_tell_us_about_you")
						),
					]}
				>
					<DvInput.TextArea
						className={styles.textarea}
						placeholder={t("tell_us_about_you")}
						onChange={(e) =>
							onChange("presentation", e.target.value)
						}
					/>
				</DvAntdFormItem>
			</DvAntdForm>

			<MembershipFormFooter
				onBackClick={() => navigate(AppRoutes.membershipFormStep2_job)}
				onNextClick={() => form.submit()}
			/>
		</>
	)
}

export default React.memo(MembershipPresentationForm)
