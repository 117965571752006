import * as React from "react"

import { ResponsiveToolCtxProvider } from "@contexts/ResponsiveToolCtx.tsx"
import { AuthCtxProvider } from "@contexts/AuthCtx.tsx"
import { MembershipLayoutCtxProvider } from "@contexts/MembershipLayoutCtx.tsx"

export const AppCtxProvider: React.FC<{
	children: React.ReactNode
}> = ({ children }) => (
	<ResponsiveToolCtxProvider>
		<AuthCtxProvider>
			<MembershipLayoutCtxProvider>
				{children}
			</MembershipLayoutCtxProvider>
		</AuthCtxProvider>
	</ResponsiveToolCtxProvider>
)
