import * as React from "react"
import * as Antd from "antd"
import * as AntdIcons from "@ant-design/icons"
import styles from "./DvPhotoUpload.module.scss"

import { AppMessage } from "@others/AppMessage.ts"
import { useTranslation } from "react-i18next"

import DsButton from "@components/general/DsButton/DsButton.tsx"

type FileType = Parameters<Antd.GetProp<Antd.UploadProps, "beforeUpload">>[0]

const getBase64 = (img: FileType, callback: (url: string) => void) => {
	const reader = new FileReader()
	reader.addEventListener("load", () => callback(reader.result as string))
	reader.readAsDataURL(img)
}

interface IProps {
	className?: string
	onSuccess?: ({ base64Image }: { base64Image: string }) => void
	onRemove?: () => void
	initialBase64Image?: string
	base64Image?: string
}

const DvPhotoUpload: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	const [loading, setLoading] = React.useState(false)

	const [imageUrl, setImageUrl] = React.useState(props.initialBase64Image)

	const beforeUpload = (file: FileType) => {
		const isJpgOrPng =
			file.type === "image/jpeg" || file.type === "image/png"

		if (!isJpgOrPng) {
			AppMessage.error(t("message_only_jpg_png_files"))
			return false
		}

		const isLt5M = file.size / 1024 / 1024 < 5

		if (!isLt5M) {
			AppMessage.error(t("message_only_files_less_than_5MB"))
			return false
		}

		return isJpgOrPng && isLt5M
	}

	const handleChange: Antd.UploadProps["onChange"] = (info) => {
		if (info.file.status === "uploading") {
			setLoading(true)
			return
		}

		if (info.file.status === "done") {
			getBase64(info.file.originFileObj as FileType, (url) => {
				setLoading(false)
				setImageUrl(url)
			})
		}
	}

	const handleRemovePhoto = () => {
		if (props.onRemove) props.onRemove()
		setImageUrl(undefined)
		setLoading(false)
	}

	React.useEffect(() => {
		if (props.onSuccess && imageUrl)
			props.onSuccess({
				base64Image: imageUrl,
			})
	}, [imageUrl])

	React.useEffect(() => {
		if (props.base64Image) setImageUrl(props.base64Image)
	}, [props.base64Image])

	return (
		<div className={`${styles.dv_photo_upload} ${props.className || ""}`}>
			<Antd.Upload
				className={styles.upload}
				name="avatar"
				listType="picture-card"
				showUploadList={false}
				beforeUpload={beforeUpload}
				onChange={handleChange}
				customRequest={({ onSuccess }) => {
					if (onSuccess) onSuccess("ok")
				}}
			>
				{imageUrl ? (
					<img
						className={styles.image}
						src={imageUrl}
						alt="avatar"
						style={{ width: "100%" }}
					/>
				) : (
					<button
						style={{ border: 0, background: "none" }}
						type="button"
					>
						{loading ? (
							<AntdIcons.LoadingOutlined />
						) : (
							<AntdIcons.PlusOutlined />
						)}
						<div style={{ marginTop: 8 }}>Upload</div>
					</button>
				)}
			</Antd.Upload>

			<div className={styles.description_container}>
				{imageUrl ? (
					<DsButton type="text" onClick={handleRemovePhoto}>
						<AntdIcons.DeleteOutlined /> {t("remove_photo")}
					</DsButton>
				) : (
					<p className={styles.image_description}>
						{t("message_the_image_has_to_be_specific")}
					</p>
				)}
			</div>
		</div>
	)
}

export default React.memo(DvPhotoUpload)
