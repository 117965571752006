import * as Antd from "antd"
import * as React from "react"

import loginFormService from "@services/loginFormService.ts"
import { IPersonalDataFormFieldType } from "@components/dataEntry/PersonalDataForm/PersonalDataForm.tsx"

export const usePersonalDataForm = (
	form: Antd.FormInstance<IPersonalDataFormFieldType>,
	initialValues: IPersonalDataFormFieldType
) => {
	const values = Antd.Form.useWatch([], form)

	const [submittable, setSubmittable] = React.useState<boolean>(false)

	const [profilePhoto, setProfilePhoto] = React.useState<string | undefined>(
		initialValues.profilePhoto
	)

	const onChange = React.useCallback(
		(
			name: keyof IPersonalDataFormFieldType,
			value: IPersonalDataFormFieldType[typeof name]
		) => {
			if (name === "profilePhoto") setProfilePhoto(value)
		},
		[]
	)

	const clearForm = React.useCallback(() => {
		loginFormService.clearFormData()
	}, [])

	React.useEffect(() => {
		form.validateFields({ validateOnly: true })
			.then(() => setSubmittable(true))
			.catch(() => setSubmittable(false))
	}, [form, values])

	React.useEffect(() => {
		form.setFieldsValue({
			name: initialValues.name,
			lastName: initialValues.lastName,
			email: initialValues.email,
			phone: initialValues.phone,
		})

		if (initialValues?.profilePhoto)
			setProfilePhoto(initialValues?.profilePhoto)
	}, [initialValues])

	return { submittable, onChange, clearForm, profilePhoto }
}
