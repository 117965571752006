import * as React from "react"
import styles from "./StartApplying.module.scss"

import { useTranslation } from "react-i18next"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"

import useAuth from "@contexts/hooks/useAuth.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import membershipFormDataService from "@services/membershipApplyFormService.ts"

interface IProps {
	className?: string
}

const StartApplying: React.FC<IProps> = (props) => {
	const { loggedUser } = useAuth()
	const { t } = useTranslation()
	const location = useLocation()
	const navigate = useNavigate()

	React.useEffect(() => {
		const getDataFromParams = async () => {
			const plan =
				AppFunctions.getUrlParam("plan") || location.state?.plan

			const paymentFrequency =
				AppFunctions.getUrlParam("paymentFrequency") ||
				location.state?.paymentFrequency

			if (plan) await membershipFormDataService.setKey("plan", plan)

			if (paymentFrequency)
				await membershipFormDataService.setKey(
					"paymentFrequency",
					paymentFrequency
				)
		}

		getDataFromParams()
	}, [])

	return (
		<div className={`${styles.start_applying} ${props.className || ""}`}>
			<h1 className={styles.title}>
				{t("hi")}
				{loggedUser?.name && `, ${loggedUser?.name}.`}
			</h1>
			<h3 className={styles.sub_title}>
				{t("message_starting_a_membership")}{" "}
				<strong>Distrito El Globo.</strong>
			</h3>

			<div className={styles.description}>
				<p className={styles.text}>
					{t("message_starting_a_membership_description_1")}
				</p>

				<p className={styles.text}>
					{t("message_starting_a_membership_description_2")}
				</p>
			</div>

			<MembershipFormFooter
				backButtonHidden
				nextButtonTest={t("start_applying")}
				onNextClick={() =>
					navigate(AppRoutes.membershipFormStep1_details, {
						replace: true,
					})
				}
			/>
		</div>
	)
}

export default React.memo(StartApplying)
