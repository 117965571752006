import * as Sentry from "@sentry/react"
import { BrowserOptions } from "@sentry/react"

export const SentryConfig: BrowserOptions = {
	dsn: "https://65d5b256fd4512802c0fa453b689c079@o4508059641511936.ingest.us.sentry.io/4508127977406464",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [
		"https://membership.elglobodistrict.com",
		"https://membership.qa.tolber.io/",
	],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}
