import * as React from "react"
import styles from "./AppImage.module.scss"

interface IProps {
	className?: string
	src?: string
	alt?: string
}

const AppImage: React.FC<IProps> = (props) => {
	return (
		<img
			src={props.src}
			alt={props.alt}
			className={`${styles.app_image} ${props.className || ""}`}
		/>
	)
}

export default React.memo(AppImage)
