import * as React from "react"
import styles from "./Background.module.scss"

import { ReactComponent as Circle } from "@assets/svg/group-293.svg"
import { ReactComponent as CelPhone } from "@assets/svg/ui_mobile_screen_1.svg"

import bgImage from "@assets/jpg/videoframe_2026_1.jpg"

interface IProps {
	className?: string
}

const Background: React.FC<IProps> = (props) => {
	return (
		<div
			className={`${styles.background} ${props.className || ""}`}
			style={{
				background: `radial-gradient(105.84% 93.6% at 111.17% 71.04%, rgba(0, 0, 0, 0.00) 0%, #141414 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
			}}
		>
			<div className={styles.video_frame_content}>
				<Circle className={styles.circle} />
				<CelPhone className={styles.cell_phone} />
			</div>
		</div>
	)
}

export default React.memo(Background)
