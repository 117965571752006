export const AppConsoleMessages = {
	log: {
		refreshing_token: "Refreshing token in the background...",
	},
	error: {
		refresh_token_failed: "Token refresh failed, logging out...",
		no_refresh_token: "No refresh token found, logging out...",
		error_500: "Internal Server Error",
		error_401: "Unauthorized, logging out...",
	},
}
