import React from "react"

import { ConfigProvider } from "antd"
import { AppLanguages } from "@constants/AppLanguages.ts"
import { Locale } from "antd/lib/locale"

import i18next from "i18next"
import enUS from "antd/es/locale/en_US"
import esEs from "antd/es/locale/es_ES"

interface AntdLangContextProps {
	children: React.ReactNode
}

export const AntdLangContext: React.FC<AntdLangContextProps> = ({
	children,
}) => {
	const code = i18next.language

	let lang: Locale

	switch (code) {
		case AppLanguages.SPANISH:
			lang = esEs
			break
		case AppLanguages.ENGLISH:
			lang = enUS
			break
		default:
			lang = enUS
			break
	}

	return <ConfigProvider locale={lang}>{children}</ConfigProvider>
}
