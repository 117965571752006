import * as React from "react"
import * as Antd from "antd"
import styles from "./DvCheckbox.module.scss"

interface IProps extends Antd.CheckboxProps {
	className?: string
}

const DvCheckbox: React.FC<IProps> = (props) => {
	return (
		<Antd.Checkbox
			{...props}
			className={`${styles.dv_checkbox} ${props.className || ""}`}
		>
			{props.children}
		</Antd.Checkbox>
	)
}

export default React.memo(DvCheckbox)
