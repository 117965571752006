import * as React from "react"
import * as Antd from "antd"
import styles from "./DsProfileDropDown.module.scss"

import { useTranslation } from "react-i18next"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useLocation, useNavigate } from "react-router-dom"

import useAuthCtx from "@contexts/hooks/useAuth.tsx"
import DvUserAvatar from "@components/dataDisplay/DvUserAvatar/DvUserAvatar.tsx"

interface IProps {
	className?: string
	children?: React.ReactNode
}

const DsProfileDropDown: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { setIsLogged, loggedUser } = useAuthCtx()

	const navigate = useNavigate()
	const location = useLocation()

	const profileItemActive = React.useMemo(
		() => location.pathname === AppRoutes.userProfile,
		[location.pathname]
	)

	const settingsItemActive = React.useMemo(
		() => location.pathname === AppRoutes.userSettings,
		[location.pathname]
	)

	const items: Antd.MenuProps["items"] = [
		{
			key: "user-data",
			label: (
				<div className={styles.drop_down_header}>
					<DvUserAvatar className={styles.avatar} />

					<div className={styles.name_container}>
						{(loggedUser?.name || loggedUser?.lastName) && (
							<h1>
								{loggedUser.name} {loggedUser.lastName}
							</h1>
						)}

						{(loggedUser?.plan || loggedUser?.corporate) && (
							<div className={styles.membership}>
								<h3>{loggedUser.plan}</h3>
								<p>{loggedUser.corporate}</p>
							</div>
						)}
					</div>
				</div>
			),
		},
		{
			className: `${styles.drop_down_item} ${profileItemActive ? styles.active : ""}`,
			key: "profile",
			label: t("profile"),
			onClick: () => {
				navigate(AppRoutes.userProfile)
			},
		},
		{
			className: `${styles.drop_down_item} ${settingsItemActive ? styles.active : ""}`,
			key: "setting",
			label: t("settings"),
			onClick: () => {
				navigate(AppRoutes.userSettings)
			},
		},
		{
			className: styles.drop_down_item,
			key: "logout",
			label: t("close_session"),
			onClick: () => {
				setIsLogged(false)
				AppFunctions.clearLoginDataFromLocalStorage()
				navigate(AppRoutes.login, { replace: true })
			},
		},
	]

	return (
		<Antd.Dropdown
			className={`${styles.profile_drop_down} ${props.className || ""}`}
			overlayClassName={styles.overlay}
			menu={{ items }}
			trigger={["click"]}
		>
			{props.children}
		</Antd.Dropdown>
	)
}

export default React.memo(DsProfileDropDown)
