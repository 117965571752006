import * as Antd from "antd"
import * as React from "react"

import { IPlanTableFormFieldType } from "@components/dataDisplay/DsPlanTable/DsPlanTable.tsx"
import { MembershipDetailsFormFieldType } from "@components/dataEntry/MembershipDetailsForm/MembershipDetailsForm.tsx"
import { IMembershipAddressFormFieldType } from "@components/dataEntry/MembershipAddressForm/MembershipAddressForm.tsx"
import { IMembershipKindOfFormFieldType } from "@components/dataEntry/MembershipKindOfForm/MembershipKindOfForm.tsx"
import { IMembershipPersonalDetailsFormFieldType } from "@components/dataEntry/MembershipPersonalDetailsForm/MembershipPersonalDetailsForm.tsx"
import { IMembershipJobsFormFieldType } from "@components/dataEntry/MembershipJobsForm/MembershipJobsForm.tsx"
import { IMembershipPresentationFormFieldType } from "@components/dataEntry/MembershipPresentationForm/MembershipPresentationForm.tsx"
import { IMembershipDigitalProfilesFormFieldType } from "@components/dataEntry/MembershipDigitalProfilesForm/MembershipDigitalProfilesForm.tsx"
import { IMembershipReferencesFormFieldType } from "@components/dataEntry/MembershipReferencesForm/MembershipReferencesForm.tsx"
import { IMembershipGloboRulesFormFieldType } from "@components/dataEntry/MembershipGloboRulesForm/MembershipGloboRulesForm.tsx"
import membershipApplyFormService from "@services/membershipApplyFormService.ts"
import dayjs from "dayjs"

export interface IMembershipApplyForm
	extends IPlanTableFormFieldType,
		MembershipDetailsFormFieldType,
		IMembershipAddressFormFieldType,
		IMembershipKindOfFormFieldType,
		IMembershipPersonalDetailsFormFieldType,
		IMembershipJobsFormFieldType,
		IMembershipPresentationFormFieldType,
		IMembershipDigitalProfilesFormFieldType,
		IMembershipReferencesFormFieldType,
		IMembershipGloboRulesFormFieldType {}

export const useMembershipApplyForm = (
	form: Antd.FormInstance<IMembershipApplyForm> | undefined,
	initialValues?: IMembershipApplyForm
) => {
	const values = Antd.Form.useWatch([], form)

	const [submittable, setSubmittable] = React.useState<boolean>(false)
	const [profilePhoto, setProfilePhoto] = React.useState<string | undefined>()
	const [checked, setChecked] = React.useState(initialValues?.doNotKnowAnyone)

	const [plan, setPlan] = React.useState<IMembershipApplyForm["plan"]>(
		initialValues?.plan
	)

	const [paymentFrequency, setPaymentFrequency] = React.useState<
		IMembershipApplyForm["paymentFrequency"]
	>(initialValues?.paymentFrequency)

	const onChange = React.useCallback(
		(
			name: keyof IMembershipApplyForm,
			value: IMembershipApplyForm[typeof name]
		) => {
			switch (name) {
				case "plan":
					setPlan(value as IMembershipApplyForm["plan"])
					break
				case "paymentFrequency":
					setPaymentFrequency(
						value as IMembershipApplyForm["paymentFrequency"]
					)
					break
				case "profilePhoto":
					setProfilePhoto(value as string)
					break
				case "doNotKnowAnyone":
					setChecked(value as boolean)
					break
			}

			return membershipApplyFormService.setKey(name, value)
		},
		[]
	)

	const clearForm = React.useCallback(() => {
		return membershipApplyFormService.clearFormData()
	}, [])

	React.useEffect(() => {
		if (!form) return

		form.validateFields({ validateOnly: true })
			.then(() => setSubmittable(true))
			.catch(() => setSubmittable(false))
	}, [form, values])

	React.useEffect(() => {
		membershipApplyFormService.getFormData().then((formData) => {
			setPlan(formData?.plan || initialValues?.plan)

			setPaymentFrequency(
				formData?.paymentFrequency || initialValues?.paymentFrequency
			)

			setProfilePhoto(
				formData?.profilePhoto ?? initialValues?.profilePhoto
			)

			setChecked(
				formData.doNotKnowAnyone ?? initialValues?.doNotKnowAnyone
			)

			if (form)
				form.setFieldsValue({
					name: formData?.name || initialValues?.name,
					lastName: formData?.lastName || initialValues?.lastName,
					email: formData?.email || initialValues?.email,
					birthdateInDayJs: formData?.birthdate
						? dayjs(formData?.birthdate)
						: initialValues?.birthdate,
					country: formData?.country || initialValues?.country,
					city: formData?.city || initialValues?.city,
					address_1: formData?.address_1 || initialValues?.address_1,
					address_2: formData?.address_2 || initialValues?.address_2,
					zipCode: formData?.zipCode || initialValues?.zipCode,
					nationality:
						formData?.nationality || initialValues?.nationality,
					gender: formData?.gender || initialValues?.gender,
					nationalId:
						formData?.nationalId || initialValues?.nationalId,
					phone: formData?.phone || initialValues?.phone,
					receivePromotionsByWhatsapp:
						formData?.receivePromotionsByWhatsapp ??
						initialValues?.receivePromotionsByWhatsapp,
					job: formData.job || initialValues?.job,
					kindOfJob: formData.kindOfJob || initialValues?.kindOfJob,
					role: formData.role || initialValues?.role,
					workEmail: formData.workEmail || initialValues?.workEmail,
					presentation:
						formData.presentation || initialValues?.presentation,
					twitter: formData.twitter || initialValues?.twitter,
					linkedIn: formData.linkedIn || initialValues?.linkedIn,
					instagram: formData.instagram || initialValues?.instagram,
					referenceName:
						formData.referenceName || initialValues?.referenceName,
					referenceEmail:
						formData.referenceEmail ||
						initialValues?.referenceEmail,
					doNotKnowAnyone:
						formData.doNotKnowAnyone ??
						initialValues?.doNotKnowAnyone,
					globoRulesAccepted:
						formData.globoRulesAccepted ??
						initialValues?.globoRulesAccepted,
					receivePromotions:
						formData.receivePromotions ??
						initialValues?.receivePromotions,
				})
		})
	}, [initialValues])

	return {
		submittable,
		onChange,
		clearForm,
		states: { plan, paymentFrequency, profilePhoto, checked },
	}
}
