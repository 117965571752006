import { Route } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"

import Login from "@pages/Login/Login.tsx"
import LoginForm from "@components/dataEntry/LoginForm/LoginForm.tsx"
import SignUpForm from "@components/dataEntry/SignUpForm/SignUpForm.tsx"
import ValidateAccountForm from "@components/dataEntry/ValidateAccountForm/ValidateAccountForm.tsx"

const LoginRoutes = () => {
	return (
		<Route path={AppRoutes.login} element={<Login />}>
			<Route index element={<LoginForm />} />

			<Route
				path={AppRoutes.validateAccount}
				element={<ValidateAccountForm />}
			/>

			<Route path={AppRoutes.signup} element={<SignUpForm />} />
		</Route>
	)
}

export default LoginRoutes
