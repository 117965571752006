import { ISignUpFormFieldType } from "@components/dataEntry/SignUpForm/SignUpForm.tsx"
import { generateAppEntity } from "@others/generateAppEntity.ts"
import indexedDBService from "@services/indexedDBService.ts"

const prefixKey = generateAppEntity("signUpFormData_")

const setKey = (
	name: keyof ISignUpFormFieldType,
	value: ISignUpFormFieldType[typeof name]
) => {
	return indexedDBService.addKey(`${prefixKey}${name}`, value)
}

const getFormData = async (): Promise<ISignUpFormFieldType> => {
	return indexedDBService.getKeysWithPrefix<ISignUpFormFieldType>(prefixKey)
}

const clearFormData = () => {
	return indexedDBService.removeKeysWithPrefix(prefixKey)
}

export default { setKey, getFormData, clearFormData }
