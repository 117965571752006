import * as React from "react"
import * as Antd from "antd"
import styles from "./DvUserAvatar.module.scss"

import useAuthCtx from "@contexts/hooks/useAuth.tsx"

interface IProps extends Antd.AvatarProps {
	className?: string
}

const DvUserAvatar: React.FC<IProps> = (props) => {
	const { loggedUser } = useAuthCtx()

	return (
		<Antd.Avatar
			{...props}
			className={`${styles.dv_user_avatar} ${props.className || ""}`}
			src={loggedUser?.profilePhoto}
		>
			{loggedUser?.name?.charAt(0) || "U"}
		</Antd.Avatar>
	)
}

export default React.memo(DvUserAvatar)
