import { useTranslation } from "react-i18next"
import { AppLanguages } from "@constants/AppLanguages.ts"
import { useMemo } from "react"

import {
	ListKindOfJobsEn,
	ListKindOfJobsEs,
} from "@constants/ListKindOfJobs.ts"

export const useListKindOfJobs = () => {
	const { i18n } = useTranslation()

	const kindOfJobs = useMemo(() => {
		const kindOfJobsRaw =
			i18n.language === AppLanguages.SPANISH
				? ListKindOfJobsEs
				: ListKindOfJobsEn

		return kindOfJobsRaw.sort((a, b) => {
			if (a.label < b.label) {
				return -1
			}
			if (a.label > b.label) {
				return 1
			}
			return 0
		})
	}, [i18n.language])

	return {
		kindOfJobs,
	}
}
