import * as React from "react"
import { useRef } from "react"
import { useTranslation } from "react-i18next"

import axios, { CancelTokenSource } from "axios"
import publicApi from "@others/public.ts"

interface IBill {
	frequency: "monthly" | "annual"
	price: number
	currency: string
	description: string
	total: number
}

interface IMonthlyBill extends IBill {
	firstPayment: number
	remainingPayments: number
}

interface IYearlyBill extends IBill {
	annuity: number
	accessFee: number
}

interface IBenefit {
	name: string
	enabled: boolean
}

interface IPlan {
	id: "silver" | "gold" | "platinum"
	name: string
	type: string
	benefits: IBenefit[]
	monthlyBill: IMonthlyBill
	annualBill: IYearlyBill
	info: string
}

export interface IPlanData {
	defaultPlan: IPlan["id"]
	defaultPaymentFrequency:
		| IPlan["monthlyBill"]["frequency"]
		| IPlan["annualBill"]["frequency"]
	plans: IPlan[]
}

const jsonName = "plan-data.json"

export const usePlanData = () => {
	const [planData, setPlanData] = React.useState<IPlanData>()
	const [loading, setLoading] = React.useState<boolean>(true)
	const { i18n } = useTranslation()

	const sourceRef = useRef<CancelTokenSource | null>(null)

	const fetchOptions = React.useCallback(() => {
		if (sourceRef.current) sourceRef.current.cancel()

		setLoading(true)

		sourceRef.current = axios.CancelToken.source()

		publicApi
			.get<IPlanData>(`json/${i18n.language}/${jsonName}`, {
				cancelToken: sourceRef.current.token,
			})
			.then((response) => {
				setPlanData(response.data)
			})
			.catch((error) => {
				if (!axios.isCancel(error)) setPlanData(undefined)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [i18n.language])

	React.useEffect(() => {
		fetchOptions()

		return () => {
			if (sourceRef.current) sourceRef.current.cancel()
		}
	}, [fetchOptions])

	return {
		planData,
		loading,
	}
}
