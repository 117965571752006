import * as React from "react"
import styles from "./InfoAccountActivated.module.scss"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import DvBackground from "@components/layout/DvBackground/DvBackground.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface IProps {
	className?: string
}

const InfoAccountActivated: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { isMobile, isDesktop } = useResponsiveToolCtx()

	return (
		<div
			className={`${styles.info_account_activated} ${props.className || ""}`}
		>
			<DvBackground />

			<div className={styles.message}>
				<div className={styles.header}>
					<h1 className={styles.title}>{t("thanks")}</h1>

					<h3 className={styles.sub_title}>
						{t("message_account_activated")}
					</h3>
				</div>

				<div className={styles.footer}>
					{isMobile && (
						<>
							<DsButton
								className={`${styles.action_button} ${styles.membership_button}`}
								htmlType="submit"
								onClick={() =>
									navigate(AppRoutes.membershipPlans, {
										replace: true,
									})
								}
							>
								{t("apply_to_a_membership")}
							</DsButton>

							<DsButton
								type="secondary"
								className={styles.action_button}
								onClick={() =>
									navigate(AppRoutes.userProfile, {
										replace: true,
									})
								}
							>
								{t("continue_browsing")}
							</DsButton>
						</>
					)}

					{isDesktop && (
						<>
							<DsButton
								type="secondary"
								className={styles.action_button}
								onClick={() =>
									navigate(AppRoutes.userProfile, {
										replace: true,
									})
								}
							>
								{t("continue_browsing")}
							</DsButton>

							<DsButton
								className={`${styles.action_button} ${styles.membership_button}`}
								htmlType="submit"
								onClick={() =>
									navigate(AppRoutes.membershipPlans, {
										replace: true,
									})
								}
							>
								{t("apply_to_a_membership")}
							</DsButton>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default React.memo(InfoAccountActivated)
