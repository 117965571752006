import React from "react"
import bgImage from "@assets/jpg/shutterstock_748258660.jpg"
import styles from "./Login.module.scss"

import { Outlet } from "react-router-dom"

import DvBackground from "@components/layout/DvBackground/DvBackground.tsx"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface IProps {
	className?: string
}

const Login: React.FC<IProps> = (props) => {
	const { isDesktop } = useResponsiveToolCtx()

	return (
		<div className={`${styles.login} ${props.className || ""}`}>
			{isDesktop && (
				<div
					className={styles.left_side_container}
					style={{
						background: `url(${bgImage}) lightgray 50% / cover no-repeat`,
					}}
				/>
			)}

			<div className={styles.right_side_container}>
				<DvBackground />

				<div className={styles.scrollable_content_container}>
					<Outlet />
				</div>
			</div>
		</div>
	)
}

export default React.memo(Login)
