import { Route } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"

import InfoDataCreateAccountReceived from "@pages/InfoDataCreateAccountReceived/InfoDataCreateAccountReceived.tsx"
import InfoAccountActivated from "@pages/InfoAccountActivated/InfoAccountActivated.tsx"

const InfoRoutes = () => {
	return (
		<Route path={AppRoutes.info}>
			<Route
				path={AppRoutes.infoDataCreateAccountReceived}
				element={<InfoDataCreateAccountReceived />}
			/>
			<Route
				path={AppRoutes.infoAccountActivated}
				element={<InfoAccountActivated />}
			/>
		</Route>
	)
}

export default InfoRoutes
