import * as React from "react"
import * as Antd from "antd"
import * as AntdIcons from "@ant-design/icons"
import styles from "./DsFooter.module.scss"

import { AppBusinessInfo } from "@constants/AppBusinessInfo.ts"
import { useTranslation } from "react-i18next"
import { AppExternalLinks } from "@constants/AppExternalLinks.ts"

import DsLogoBlancoElGlobo from "@components/others/DsLogoBlancoElGlobo/DsLogoBlancoElGlobo.tsx"

interface IProps {
	className?: string
}

const DsFooter: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	const handleLogoClick = (
		logoType:
			| "instagram"
			| "linkedin"
			| "email"
			| "infoEmail"
			| "membershipEmail"
			| "companyPhone"
	) => {
		const {
			instagram,
			linkedin,
			emailLinkToSend,
			infoEmailLinkToSend,
			membershipEmailLinkToSend,
			whatsappLinkToSend,
		} = AppExternalLinks()

		let urlToOpen = ""
		switch (logoType) {
			case "instagram":
				urlToOpen = instagram
				break
			case "linkedin":
				urlToOpen = linkedin
				break
			case "email":
				urlToOpen = emailLinkToSend
				break
			case "infoEmail":
				urlToOpen = infoEmailLinkToSend
				break
			case "membershipEmail":
				urlToOpen = membershipEmailLinkToSend
				break
			case "companyPhone":
				urlToOpen = whatsappLinkToSend
				break
		}

		window.open(urlToOpen, "_blank")
	}

	return (
		<footer className={`${styles.ds_footer} ${props.className || ""}`}>
			<div className={styles.logo}>
				<DsLogoBlancoElGlobo
					type="horizontal"
					width={260}
					height={66}
				/>

				<Antd.Divider className={styles.divider} />
			</div>

			<div className={styles.bottom}>
				<div className={styles.contact}>
					<div className={styles.icon_container}>
						<AntdIcons.InstagramFilled
							className={styles.icon}
							onClick={() => handleLogoClick("instagram")}
						/>
						<AntdIcons.LinkedinFilled
							className={styles.icon}
							onClick={() => handleLogoClick("linkedin")}
						/>
						<AntdIcons.MailFilled
							className={styles.icon}
							onClick={() => handleLogoClick("email")}
						/>
					</div>

					<div className={styles.info}>
						<h1 className={styles.title}>{t("contact_us")}</h1>

						<h3
							className={styles.subtitle}
							onClick={() => handleLogoClick("infoEmail")}
						>
							{AppBusinessInfo.infoEmail}
						</h3>

						<h3
							className={styles.subtitle}
							onClick={() => handleLogoClick("membershipEmail")}
						>
							{AppBusinessInfo.membershipEmail}
						</h3>

						<h3
							className={styles.subtitle}
							onClick={() => handleLogoClick("companyPhone")}
						>
							{AppBusinessInfo.companyPhone.prettier}
						</h3>
					</div>

					<p className={styles.copyright}>
						Copyright © 2024 El Globo
					</p>
				</div>
			</div>
		</footer>
	)
}

export default React.memo(DsFooter)
