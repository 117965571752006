import * as React from "react"
import styles from "./TermsAndConditions.module.scss"

interface IProps {
	className?: string
}

const TermsAndConditions: React.FC<IProps> = (props) => {
	return (
		<div
			className={`${styles.terms_and_conditions} ${props.className || ""}`}
		>
			<h1 className={styles.title}>Este es un subtítulo</h1>

			<article className={styles.article}>
				<h3>Este es otro subtítulo</h3>

				<p>
					Doloribus ut dolore blanditiis rerum. Modi dolor consequatur
					atque explicabo vero ut voluptas. Magni quam iure et sunt.
					Qui possimus fugiat eum. Fuga et nemo sit et velit maxime et
					hic. Ut mollitia consectetur iure hic et inventore. Repellat
					quis quis et sint omnis cumque dolorem. Ad neque qui.
					Maiores voluptas deleniti. Mollitia sed provident totam odio
					neque blanditiis a sunt architecto. Quidem qui dolores nam
					vero enim voluptatem ipsum nisi. Quibusdam dicta qui. Quasi
					earum voluptatem qui et saepe ipsam nulla quod. Delectus
					necessitatibus consectetur iusto. Blanditiis id ut
					consequatur quam. Iusto maxime repellendus ullam fugiat eius
					non ducimus aut voluptatibus.
				</p>
			</article>

			<article className={styles.article}>
				<h3>Este es otro subtítulo</h3>

				<p>
					Doloribus ut dolore blanditiis rerum. Modi dolor consequatur
					atque explicabo vero ut voluptas. Magni quam iure et sunt.
					Qui possimus fugiat eum. Fuga et nemo sit et velit maxime et
					hic. Ut mollitia consectetur iure hic et inventore. Repellat
					quis quis et sint omnis cumque dolorem. Ad neque qui.
					Maiores voluptas deleniti. Mollitia sed provident totam odio
					neque blanditiis a sunt architecto. Quidem qui dolores nam
					vero enim voluptatem ipsum nisi. Quibusdam dicta qui. Quasi
					earum voluptatem qui et saepe ipsam nulla quod. Delectus
					necessitatibus consectetur iusto. Blanditiis id ut
					consequatur quam. Iusto maxime repellendus ullam fugiat eius
					non ducimus aut voluptatibus.
				</p>
			</article>

			<article className={styles.article}>
				<h3>Este es otro subtítulo</h3>

				<p>
					Doloribus ut dolore blanditiis rerum. Modi dolor consequatur
					atque explicabo vero ut voluptas. Magni quam iure et sunt.
					Qui possimus fugiat eum. Fuga et nemo sit et velit maxime et
					hic. Ut mollitia consectetur iure hic et inventore. Repellat
					quis quis et sint omnis cumque dolorem. Ad neque qui.
					Maiores voluptas deleniti. Mollitia sed provident totam odio
					neque blanditiis a sunt architecto. Quidem qui dolores nam
					vero enim voluptatem ipsum nisi. Quibusdam dicta qui. Quasi
					earum voluptatem qui et saepe ipsam nulla quod. Delectus
					necessitatibus consectetur iusto. Blanditiis id ut
					consequatur quam. Iusto maxime repellendus ullam fugiat eius
					non ducimus aut voluptatibus.
				</p>
			</article>
		</div>
	)
}

export default React.memo(TermsAndConditions)
