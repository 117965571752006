import * as React from "react"
import styles from "./NotFound.module.scss"

interface IProps {
	className?: string
}

const NotFound: React.FC<IProps> = (props) => {
	return (
		<div className={`${styles.not_found} ${props.className || ""}`}>
			NotFound Content
		</div>
	)
}

export default React.memo(NotFound)
