import * as React from "react"
import * as Antd from "antd"
import styles from "./DvAntdForm.module.scss"

interface IProps<T> extends Antd.FormProps<T> {
	className?: string
	children: React.ReactNode
}

const DvAntdForm = <T,>(
	{ className, ...props }: IProps<T>,
	ref: React.Ref<Antd.FormInstance<T>>
) => {
	const formRef = React.useRef<Antd.FormInstance<T> | null>(null)

	React.useImperativeHandle(
		ref,
		() => formRef.current as Antd.FormInstance<T>,
		[formRef]
	)

	return (
		<Antd.Form<T>
			ref={formRef}
			className={`${styles.dv_antd_form} ${className || ""}`}
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			autoComplete="off"
			layout="vertical"
			{...props}
		>
			{props.children}
		</Antd.Form>
	)
}

export default React.forwardRef(DvAntdForm) as <T>(
	props: IProps<T> & { ref?: React.Ref<Antd.FormInstance<T>> }
) => JSX.Element
