import * as React from "react"
import styles from "./DvMessage_1.module.scss"

import { useTranslation } from "react-i18next"
import { AppExternalLinks } from "@constants/AppExternalLinks.ts"

interface IProps {
	className?: string
}

const DvMessage_1: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { membershipEmailLinkToSend } = AppExternalLinks()

	return (
		<p className={`${styles.dv_message_1} ${props.className || ""}`}>
			{t("message_membership_applied_contact_us")}{" "}
			<span
				onClick={() => {
					window.open(membershipEmailLinkToSend, "_blank")
				}}
			>
				{t("support_team")}
			</span>
			.
		</p>
	)
}

export default React.memo(DvMessage_1)
