import * as React from "react"
import styles from "./PlanDetailItem.module.scss"

interface IProps {
	className?: string
	title: string
	subtitle?: string
	currency: string
	value: number
	unit?: string
	strongText?: boolean
	strongBorder?: boolean
}

const PlanDetailItem: React.FC<IProps> = (props) => {
	const strongTextClass = props.strongText ? styles.strong_text : ""
	const strongBorderClass = props.strongBorder ? styles.strong_border : ""

	return (
		<div
			className={`${styles.plan_detail_item} ${props.className || ""} ${strongTextClass} ${strongBorderClass}`}
		>
			<div className={styles.text_container}>
				<h1>{props.title}</h1>
				{props.subtitle && <h3>{props.subtitle}</h3>}
			</div>
			<div className={styles.price_container}>
				<label className={styles.currency}>{props.currency}</label>
				<span className={styles.value}>{props.value}</span>

				{props.unit && (
					<span className={styles.unit}>{props.unit}</span>
				)}
			</div>
		</div>
	)
}

export default React.memo(PlanDetailItem)
