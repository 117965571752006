import * as React from "react"

import { useTranslation } from "react-i18next"

interface IGenders {
	value: string
	label: string
}

export const useListOfGenders = () => {
	const { t } = useTranslation()

	const genders = React.useMemo<IGenders[]>(
		() => [
			{
				value: "man",
				label: t("man"),
			},
			{
				value: "woman",
				label: t("woman"),
			},
			{
				value: "non_binary",
				label: t("non_binary"),
			},
			{
				value: "prefer_not_to_say",
				label: t("prefer_not_to_say"),
			},
		],
		[t]
	)

	return {
		genders,
	}
}
