import * as React from "react"

interface ICtx {
	nextButtonLoading: boolean
	setNextButtonLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const InitialCtxState: ICtx = {
	nextButtonLoading: false,
	setNextButtonLoading: () => {},
}

export const Context = React.createContext<ICtx>(InitialCtxState)

const CtxProvider: React.FC<{
	children: React.ReactNode
}> = ({ children }) => {
	const [nextButtonLoading, setNextButtonLoading] = React.useState<
		ICtx["nextButtonLoading"]
	>(InitialCtxState.nextButtonLoading)

	const contextValue = React.useMemo(
		() => ({
			nextButtonLoading,
			setNextButtonLoading,
		}),
		[nextButtonLoading, setNextButtonLoading]
	)

	return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export const MembershipLayoutCtxProvider = CtxProvider
