import { generateAppEntity } from "@others/generateAppEntity.ts"
import { IMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"
import IndexedDBService from "@services/indexedDBService.ts"
import indexedDBService from "@services/indexedDBService.ts"

const prefixKey = generateAppEntity("applyMembershipFormData_")

const setKey = async (
	name: keyof IMembershipApplyForm,
	value: IMembershipApplyForm[typeof name]
) => {
	return indexedDBService.addKey(`${prefixKey}${name}`, value)
}

const getFormData = async (): Promise<IMembershipApplyForm> => {
	return indexedDBService.getKeysWithPrefix<IMembershipApplyForm>(prefixKey)
}

const removeKey = async (name: keyof IMembershipApplyForm) => {
	return IndexedDBService.removeKey(`${prefixKey}${name}`)
}

const clearFormData = () => {
	return indexedDBService.removeKeysWithPrefix(prefixKey)
}

export default { setKey, removeKey, getFormData, clearFormData }
