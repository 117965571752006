import * as React from "react"
import * as AntdIcons from "@ant-design/icons"
import styles from "./MenuItem.module.scss"

import AppTextEllipsis from "@components/others/AppTextEllipsis/AppTextEllipsis.tsx"

interface IProps {
	className?: string
	label: string
	icon?: React.ReactNode
	onClick?: () => void
}

const MenuItem: React.FC<IProps> = (props) => {
	return (
		<>
			<div className={`${styles.menu_item} ${props.className || ""}`}>
				<AppTextEllipsis className={styles.label}>
					{props.label}
				</AppTextEllipsis>

				{props.icon || (
					<AntdIcons.RightOutlined
						className={styles.icon}
						onClick={props.onClick}
					/>
				)}
			</div>
			<div className={styles.line} />
		</>
	)
}

export default React.memo(MenuItem)
