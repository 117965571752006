import * as React from "react"
import * as Antd from "antd"
import styles from "./AppTextEllipsis.module.scss"

interface IProps {
	text?: string
	rootClassName?: string
	style?: React.CSSProperties
	rootStyle?: React.CSSProperties
	className?: string
	maxWith?: number | string
	children?: string
}

const AppTextEllipsis: React.FC<IProps> = (props) => {
	const textContainer = React.useRef<HTMLSpanElement>(null)
	const [textWidth, setTextWidth] = React.useState<number>(0)
	const autoWidth = React.useMemo(() => !props.maxWith, [props.maxWith])

	const textValue = React.useMemo(
		() => props.text || props.children,
		[props.text, props.children]
	)

	React.useEffect(() => {
		if (!autoWidth) return

		const resizeObserver = new ResizeObserver((entries) => {
			setTextWidth(entries[0].contentRect.width)
		})

		resizeObserver.observe(textContainer.current!)

		return () => resizeObserver.disconnect()
	}, [])

	return (
		<span
			className={`${styles.app_text_ellipsis} ${props.rootClassName}`}
			ref={textContainer}
			style={props.rootStyle}
		>
			{autoWidth ? (
				<>
					{textWidth && (
						<Antd.Typography.Text
							className={props.className}
							ellipsis={{ tooltip: textValue }}
							style={{
								maxWidth: textWidth,
								...props.style,
							}}
						>
							{textValue}
						</Antd.Typography.Text>
					)}
				</>
			) : (
				<Antd.Typography.Text
					className={props.className}
					ellipsis={{ tooltip: textValue }}
					style={{
						maxWidth: props.maxWith,
						...props.style,
					}}
				>
					{textValue}
				</Antd.Typography.Text>
			)}
		</span>
	)
}

export default React.memo(AppTextEllipsis)
