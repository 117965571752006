import { Route } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"

import MembershipLayout from "@components/layout/MembershipLayout/MembershipLayout.tsx"
import MembershipPlans from "@pages/MembershipPlans/MembershipPlans.tsx"
import StartApplying from "@pages/StartApplying/StartApplying.tsx"
import ProtectedRoute from "@components/others/PrivateRoute/PrivateRoute.tsx"
import MembershipDetailsForm from "@components/dataEntry/MembershipDetailsForm/MembershipDetailsForm.tsx"
import MembershipKindOfForm from "@components/dataEntry/MembershipKindOfForm/MembershipKindOfForm.tsx"
import MembershipPersonalDetailsForm from "@components/dataEntry/MembershipPersonalDetailsForm/MembershipPersonalDetailsForm.tsx"
import MembershipJobsForm from "@components/dataEntry/MembershipJobsForm/MembershipJobsForm.tsx"
import MembershipPresentationForm from "@components/dataEntry/MembershipPresentationForm/MembershipPresentationForm.tsx"
import MembershipDigitalProfilesForm from "@components/dataEntry/MembershipDigitalProfilesForm/MembershipDigitalProfilesForm.tsx"
import MembershipReferencesForm from "@components/dataEntry/MembershipReferencesForm/MembershipReferencesForm.tsx"
import MembershipGloboRulesForm from "@components/dataEntry/MembershipGloboRulesForm/MembershipGloboRulesForm.tsx"
import MembershipSendForm from "@components/dataEntry/MembershipSendForm/MembershipSendForm.tsx"
import MembershipFormSteps from "@pages/MembershipFormSteps/MembershipFormSteps.tsx"
import MembershipAddressForm from "@components/dataEntry/MembershipAddressForm/MembershipAddressForm.tsx"

const MembershipRoutes = () => {
	return (
		<Route
			path={AppRoutes.membership}
			element={
				<ProtectedRoute>
					<MembershipLayout />
				</ProtectedRoute>
			}
		>
			<Route
				path={AppRoutes.membershipPlans}
				element={<MembershipPlans />}
			/>
			<Route
				path={AppRoutes.membershipStartApplying}
				element={<StartApplying />}
			/>

			<Route
				path={AppRoutes.membershipFormStep1}
				element={<MembershipFormSteps step={1} />}
			>
				<Route
					path={AppRoutes.membershipFormStep1_details}
					element={<MembershipDetailsForm />}
				/>
				<Route
					path={AppRoutes.membershipFormStep1_address}
					element={<MembershipAddressForm />}
				/>
				<Route
					path={AppRoutes.membershipFormStep1_kind_of_membership}
					element={<MembershipKindOfForm />}
				/>
			</Route>

			<Route
				path={AppRoutes.membershipFormStep2}
				element={<MembershipFormSteps step={2} />}
			>
				<Route
					path={AppRoutes.membershipFormStep2_personalDetails}
					element={<MembershipPersonalDetailsForm />}
				/>
				<Route
					path={AppRoutes.membershipFormStep2_job}
					element={<MembershipJobsForm />}
				/>
			</Route>

			<Route
				path={AppRoutes.membershipFormStep3}
				element={<MembershipFormSteps step={3} />}
			>
				<Route
					path={AppRoutes.membershipFormStep3_presentation}
					element={<MembershipPresentationForm />}
				/>
				<Route
					path={AppRoutes.membershipFormStep3_digital_profiles}
					element={<MembershipDigitalProfilesForm />}
				/>
				<Route
					path={AppRoutes.membershipFormStep3_references}
					element={<MembershipReferencesForm />}
				/>
			</Route>

			<Route
				path={AppRoutes.membershipFormStep4}
				element={<MembershipFormSteps step={4} />}
			>
				<Route
					path={AppRoutes.membershipFormStep4_globoRules}
					element={<MembershipGloboRulesForm />}
				/>
			</Route>

			<Route
				path={AppRoutes.membershipFormStep5}
				element={<MembershipFormSteps step={5} />}
			>
				<Route
					path={AppRoutes.membershipFormStep5_sendMembership}
					element={<MembershipSendForm />}
				/>
			</Route>

			<Route
				path={AppRoutes.membershipSuccess}
				element={<MembershipFormSteps step={6} />}
			/>
		</Route>
	)
}

export default MembershipRoutes
