const DATABASE_NAME = "MyDatabase"
const DATABASE_VERSION = 2
const OBJECT_STORE_NAME = "MyStore"

const openDatabase = (): Promise<IDBDatabase> => {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open(DATABASE_NAME, DATABASE_VERSION)

		request.onupgradeneeded = function (event: any) {
			const db = event.target.result
			if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
				db.createObjectStore(OBJECT_STORE_NAME)
			}
		}

		request.onsuccess = function (event: any) {
			const db = event.target.result
			if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
				console.error(`Object store "${OBJECT_STORE_NAME}" not found.`)
				reject(`Object store "${OBJECT_STORE_NAME}" not found.`)
				return
			}
			resolve(db)
		}

		request.onerror = function () {
			console.error("Failed to open IndexedDB.")
			reject("Failed to open IndexedDB.")
		}
	})
}

const addKey = async <IValue = any>(
	key: string,
	value: IValue
): Promise<void> => {
	try {
		const db = await openDatabase()
		const transaction = db.transaction(OBJECT_STORE_NAME, "readwrite")
		const store = transaction.objectStore(OBJECT_STORE_NAME)
		store.put(value, key)

		transaction.oncomplete = () => {}

		transaction.onerror = () => {
			console.error("Failed to store data in IndexedDB.")
		}
	} catch (error) {
		console.error(error)
	}
}

const getKey = async <IResponse = any>(key: string): Promise<IResponse> => {
	try {
		const db = await openDatabase()
		const transaction = db.transaction(OBJECT_STORE_NAME, "readonly")
		const store = transaction.objectStore(OBJECT_STORE_NAME)

		return new Promise((resolve, reject) => {
			const dataRequest = store.get(key)

			dataRequest.onsuccess = function () {
				resolve(dataRequest.result)
			}

			dataRequest.onerror = () => {
				console.error(
					`Failed to retrieve data for key "${key}" from IndexedDB.`
				)
				reject(`Failed to retrieve data for key "${key}".`)
			}
		})
	} catch (error) {
		console.error(error)
		throw error
	}
}

const updateKey = async <IValue = any>(
	key: string,
	value: IValue
): Promise<void> => {
	try {
		const db = await openDatabase()
		const transaction = db.transaction(OBJECT_STORE_NAME, "readwrite")
		const store = transaction.objectStore(OBJECT_STORE_NAME)
		store.put(value, key)

		transaction.oncomplete = () => {}

		transaction.onerror = () => {
			console.error("Failed to update data in IndexedDB.")
		}
	} catch (error) {
		console.error(error)
	}
}

const removeKey = async (key: string): Promise<void> => {
	try {
		const db = await openDatabase()
		const transaction = db.transaction(OBJECT_STORE_NAME, "readwrite")
		const store = transaction.objectStore(OBJECT_STORE_NAME)
		const deleteRequest = store.delete(key)

		deleteRequest.onsuccess = () => {}

		deleteRequest.onerror = () => {
			console.error(
				`Failed to delete data with key "${key}" from IndexedDB.`
			)
		}
	} catch (error) {
		console.error(error)
	}
}

const deleteDatabase = () => {
	const deleteRequest = indexedDB.deleteDatabase(DATABASE_NAME)

	deleteRequest.onsuccess = () => {}

	deleteRequest.onerror = () => {
		console.error("Failed to delete database")
	}
}

const getKeysWithPrefix = async <IResponse = any>(
	prefix: string
): Promise<{ [key: string]: IResponse }> => {
	try {
		const db = await openDatabase()
		const transaction = db.transaction(OBJECT_STORE_NAME, "readonly")
		const store = transaction.objectStore(OBJECT_STORE_NAME)

		return new Promise((resolve, reject) => {
			const results: { [key: string]: IResponse } = {}
			const cursorRequest = store.openCursor()

			cursorRequest.onsuccess = (event: any) => {
				const cursor = event.target.result
				if (cursor) {
					if (cursor.key.startsWith(prefix)) {
						const strippedKey = cursor.key.replace(prefix, "")
						results[strippedKey] = cursor.value
					}
					cursor.continue()
				} else {
					resolve(results)
				}
			}

			cursorRequest.onerror = () => {
				console.error(
					`Failed to retrieve keys with prefix "${prefix}" from IndexedDB.`
				)
				reject(`Failed to retrieve keys with prefix "${prefix}".`)
			}
		})
	} catch (error) {
		console.error(error)
		throw error
	}
}

const removeKeysWithPrefix = async (prefix: string): Promise<void> => {
	try {
		const db = await openDatabase()
		const transaction = db.transaction(OBJECT_STORE_NAME, "readwrite")
		const store = transaction.objectStore(OBJECT_STORE_NAME)

		return new Promise((resolve, reject) => {
			const cursorRequest = store.openCursor()

			cursorRequest.onsuccess = (event: any) => {
				const cursor = event.target.result
				if (cursor) {
					if (cursor.key.startsWith(prefix)) {
						store.delete(cursor.key)
					}
					cursor.continue()
				} else {
					resolve()
				}
			}

			cursorRequest.onerror = () => {
				console.error(
					`Failed to retrieve keys with prefix "${prefix}" from IndexedDB.`
				)
				reject(`Failed to retrieve keys with prefix "${prefix}".`)
			}
		})
	} catch (error) {
		console.error(error)
		throw error
	}
}

export default {
	addKey,
	getKey,
	updateKey,
	removeKey,
	deleteDatabase,
	getKeysWithPrefix,
	removeKeysWithPrefix,
}
