import * as React from "react"
import styles from "./DsPlanPrice.module.scss"

import { useTranslation } from "react-i18next"

import DsButton from "@components/general/DsButton/DsButton.tsx"

interface IProps {
	className?: string
	frequency: string
	currency: string
	price: number
	description: string
	onClick?: () => void
	selected: boolean
}

const DsPlanPrice: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	const highLightedClass = props.selected ? styles.highLighted : ""

	return (
		<div className={`${styles.ds_plan_price} ${props.className || ""}`}>
			<div className={`${styles.plan_price_content} ${highLightedClass}`}>
				<h3 className={`${styles.period} `}>{props.frequency}</h3>

				<div className={styles.price}>
					<label>{props.currency}</label>
					<h1>{props.price}</h1>
				</div>

				<p className={styles.program}>{props.description}</p>

				<DsButton
					className={styles.select_plan_button}
					onClick={props.onClick}
				>
					{t("select_plan")}
				</DsButton>
			</div>
		</div>
	)
}

export default React.memo(DsPlanPrice)
