import * as React from "react"
import * as Antd from "antd"
import styles from "./SignUpForm.module.scss"

import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppMessage } from "@others/AppMessage.ts"
import { useListOfAllCountries } from "@hooks/useMeetUsOptions.tsx"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useSignUpForm } from "@hooks/useSignUpForm.tsx"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvCheckbox from "@components/dataEntry/DvCheckbox/DvCheckbox.tsx"
import DvSelect from "@components/dataEntry/DvSelect/DvSelect.tsx"
import authService from "@services/authService.ts"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import DvInfoModal from "@components/feedback/DvInfoModal/DvInfoModal.tsx"
import PrivacyPolicy from "@components/dataEntry/SignUpForm/PrivacyPolicy/PrivacyPolicy.tsx"
import TermsAndConditions from "@components/dataEntry/SignUpForm/TermsAndConditions/TermsAndConditions.tsx"
import validationRules from "@others/validationRules.ts"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"

export interface ISignUpFormFieldType {
	name?: string
	lastName?: string
	email?: string
	password?: string
	passwordConfirmation?: string
	phone?: string
	company?: string
	howDidYouMeetUs?: string
	receivePromotions?: boolean
}

interface IProps {
	className?: string
}

const SignUpForm: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { options, loading } = useListOfAllCountries()

	const [form] = Antd.Form.useForm<ISignUpFormFieldType>()
	const [isCreatingAccount, setIsCreatingAccount] = React.useState(false)

	const navigate = useNavigate()
	const location = useLocation()

	const initialValues: ISignUpFormFieldType = React.useMemo(
		() => ({
			receivePromotions: true,
			email: location.state?.email,
		}),
		[location.state]
	)

	const { onChange, clearForm } = useSignUpForm(form, initialValues)

	const [privacyPolicyModalOpen, setPrivacyPolicyModalOpen] =
		React.useState(false)

	const [termsAndConditionsModalOpen, setTermsAndConditionsModalOpen] =
		React.useState(false)

	const onFinish = (values: ISignUpFormFieldType) => {
		setIsCreatingAccount(true)
		const currentDomain = window.location.origin

		const isSignUpDueExtMembershipApplicant =
			location.state?.plan && location.state?.paymentFrequency

		const forwardTo = !isSignUpDueExtMembershipApplicant
			? currentDomain + AppRoutes.infoAccountActivated
			: currentDomain +
				AppRoutes.membershipStartApplying +
				`?plan=${location.state.plan}&paymentFrequency=${location.state.paymentFrequency}`

		authService
			.signup({ ...values, forwardTo })
			.then((response) => {
				if (response.account_created && !response.email_sent) {
					AppMessage.warning(t("message_we_could_not_send_email"))
				} else {
					clearForm().then(() => {
						navigate(AppRoutes.infoDataCreateAccountReceived, {
							replace: true,
						})
					})
				}
			})
			.catch(({ message }) => {
				AppMessage.error(message || t("something_went_wrong"))
			})
			.finally(() => {
				setIsCreatingAccount(false)
			})
	}

	return (
		<>
			<DvAntdForm<ISignUpFormFieldType>
				form={form}
				className={`${styles.signup_form} ${props.className || ""}`}
				initialValues={initialValues}
				onFinish={onFinish}
			>
				<header className={styles.header}>
					<p>{t("message_let_start_with_a_new_account")}</p>
					<p>{t("lest_start")}</p>
				</header>

				<main className={styles.main}>
					<div className={styles.row}>
						<DvAntdFormItem<ISignUpFormFieldType>
							label={t("name")}
							name="name"
							rules={[
								validationRules.requiredFieldRule(
									t("please_enter_your_name")
								),
							]}
						>
							<DvInput
								placeholder={t("insert_your_name")}
								onChange={(e) =>
									onChange("name", e.target.value)
								}
							/>
						</DvAntdFormItem>

						<DvAntdFormItem<ISignUpFormFieldType>
							label={t("lastName")}
							name="lastName"
							rules={[
								validationRules.requiredFieldRule(
									t("please_enter_your_lastName")
								),
							]}
						>
							<DvInput
								placeholder={t("insert_your_lastName")}
								onChange={(e) =>
									onChange("lastName", e.target.value)
								}
							/>
						</DvAntdFormItem>

						<DvAntdFormItem<ISignUpFormFieldType>
							label={t("email")}
							name="email"
							validateDebounce={1500}
							rules={[
								validationRules.requiredFieldRule(
									t("please_enter_your_email")
								),
								validationRules.emailRule(
									t("your_email_is_not_valid")
								),
							]}
						>
							<DvInput
								placeholder={t("insert_your_email")}
								onChange={(e) =>
									onChange("email", e.target.value)
								}
							/>
						</DvAntdFormItem>

						<DvAntdFormItem<ISignUpFormFieldType>
							className={`${styles.form_item} ${styles.password_item}`}
							label={t("password")}
							name="password"
							rules={[
								validationRules.requiredFieldRule(
									t("please_enter_your_password")
								),
							]}
						>
							<DvInput.Password
								placeholder={t("insert_your_password")}
							/>
						</DvAntdFormItem>

						<DvAntdFormItem<ISignUpFormFieldType>
							label={t("confirm_password")}
							name="passwordConfirmation"
							rules={[
								validationRules.requiredFieldRule(
									t("please_enter_your_password")
								),
							]}
						>
							<DvInput.Password
								placeholder={t("insert_your_password")}
							/>
						</DvAntdFormItem>

						<DvAntdFormItem<ISignUpFormFieldType>
							label={t("phone")}
							name="phone"
							validateDebounce={1500}
							rules={[
								validationRules.requiredFieldRule(
									t("please_enter_your_phone")
								),
								validationRules.phoneRule(),
							]}
						>
							<DvInput
								placeholder={t("insert_your_phone")}
								onChange={(e) =>
									onChange("phone", e.target.value)
								}
							/>
						</DvAntdFormItem>
					</div>

					<Antd.Divider className={styles.divider} />

					<div className={styles.row}>
						<DvAntdFormItem<ISignUpFormFieldType>
							label={t("company")}
							name="company"
							rules={[
								validationRules.requiredFieldRule(
									t("please_enter_your_company")
								),
							]}
						>
							<DvInput
								placeholder={t("insert_your_company")}
								onChange={(e) =>
									onChange("company", e.target.value)
								}
							/>
						</DvAntdFormItem>

						<DvAntdFormItem<ISignUpFormFieldType>
							className={`${styles.form_item} ${styles.how_did_you_meet_us_item}`}
							label={t("how_did_you_meet_us")}
							name="howDidYouMeetUs"
							rules={[
								validationRules.requiredFieldRule(
									t("please_select_an_option")
								),
							]}
						>
							<DvSelect
								options={options}
								loading={loading}
								onChange={(val) => {
									onChange("howDidYouMeetUs", val)
								}}
							/>
						</DvAntdFormItem>
					</div>

					<p className={styles.text}>
						{t("message_accepting_terms")}{" "}
						<span onClick={() => setPrivacyPolicyModalOpen(true)}>
							{t("privacy_policy")}
						</span>{" "}
						{t("and")}{" "}
						<span
							onClick={() => setTermsAndConditionsModalOpen(true)}
						>
							{t("terms_and_conditions")}
						</span>
						.
					</p>

					<div className={styles.row}>
						<DvAntdFormItem<ISignUpFormFieldType>
							className={styles.receive_promotions_item}
							name="receivePromotions"
							valuePropName="checked"
						>
							<DvCheckbox
								className={styles.checkbox}
								onChange={(e) =>
									onChange(
										"receivePromotions",
										e.target.checked
									)
								}
							>
								{t("message_receive_notifications")}
							</DvCheckbox>
						</DvAntdFormItem>
					</div>
				</main>

				<div className={styles.footer}>
					<DsButton
						className={styles.action_button}
						htmlType="submit"
						loading={isCreatingAccount}
					>
						{t("create_account")}
					</DsButton>
				</div>
			</DvAntdForm>

			<DvInfoModal
				open={privacyPolicyModalOpen}
				title={AppFunctions.capitalizeFirstChar(t("privacy_policy"))}
				onClose={() => setPrivacyPolicyModalOpen(false)}
			>
				<PrivacyPolicy />
			</DvInfoModal>

			<DvInfoModal
				open={termsAndConditionsModalOpen}
				title={AppFunctions.capitalizeFirstChar(
					t("terms_and_conditions")
				)}
				onClose={() => setTermsAndConditionsModalOpen(false)}
			>
				<TermsAndConditions />
			</DvInfoModal>
		</>
	)
}

export default React.memo(SignUpForm)
