import * as React from "react"

interface Country {
	value: string
	label: string
}

export const useListOfAllCountries = () => {
	const [countries, setCountries] = React.useState<Country[]>([])
	const [loading, setLoading] = React.useState<boolean>(true)
	const [error, setError] = React.useState<string | null>(null)

	const fetchCountries = React.useCallback(async () => {
		try {
			const response = await fetch("https://restcountries.com/v3.1/all")

			if (!response.ok) throw new Error("Failed to fetch countries")

			const data = await response.json()

			const sortedCountries = data
				.map((country: any) => ({
					value: country.cca2,
					label: country.name.common,
				}))
				.sort((a: Country, b: Country) =>
					a.label.localeCompare(b.label)
				)

			setCountries(sortedCountries)
		} catch (error: any) {
			setError(error?.message)
			console.error("Error fetching countries:", error)
		} finally {
			setLoading(false)
		}
	}, [])

	React.useEffect(() => {
		fetchCountries()
	}, [fetchCountries])

	return {
		countries,
		loading,
		error,
	}
}
