import * as Antd from "antd"
import * as React from "react"

import { ISignUpFormFieldType } from "@components/dataEntry/SignUpForm/SignUpForm.tsx"

import signUpFormService from "@services/signUpFormService.ts"

export const useSignUpForm = (
	form: Antd.FormInstance<ISignUpFormFieldType>,
	initialValues: ISignUpFormFieldType
) => {
	const values = Antd.Form.useWatch([], form)

	const [submittable, setSubmittable] = React.useState<boolean>(false)

	const onChange = React.useCallback(
		(
			name: keyof ISignUpFormFieldType,
			value: ISignUpFormFieldType[typeof name]
		) => signUpFormService.setKey(name, value),
		[]
	)

	const clearForm = React.useCallback(() => {
		return signUpFormService.clearFormData()
	}, [])

	React.useEffect(() => {
		form.validateFields({ validateOnly: true })
			.then(() => setSubmittable(true))
			.catch(() => setSubmittable(false))
	}, [form, values])

	React.useEffect(() => {
		signUpFormService.getFormData().then((formData) => {
			form.setFieldsValue({
				name: formData?.name || initialValues.name,
				lastName: formData?.lastName || initialValues.lastName,
				email: formData?.email || initialValues.email,
				password: formData?.password || initialValues.password,
				phone: formData?.phone || initialValues.phone,
				company: formData?.company || initialValues.company,

				passwordConfirmation:
					formData?.passwordConfirmation ||
					initialValues.passwordConfirmation,

				howDidYouMeetUs:
					formData?.howDidYouMeetUs || initialValues.howDidYouMeetUs,

				receivePromotions:
					formData?.receivePromotions ??
					initialValues.receivePromotions,
			})
		})
	}, [])

	return { initialValues, submittable, onChange, clearForm }
}
